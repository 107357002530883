import api from '@services/client/api';
import TableServicesBoosters from '@components/Dashboard/Booster/availableServices';
import TableFinalizedBoosters from '@components/Dashboard/Booster/finalized';
import TableInProgressBoosters from '@components/Dashboard/Booster/inProgress';
import ResumeBooster from '@components/Dashboard/Booster/resumeBooster';
import { useAuth } from '@context/AuthContext';
import { useEffect, useState } from 'react';

type Props = {};

const Booster = (props: Props) => {
  const { user, token, signOut } = useAuth();

  const [data, setData] = useState({
    availableRequests: [],
    currentRequests: [],
    finalizedRequests: [],
    gameBooster: '',
  });

  useEffect(() => {
    async function loadServices() {
      try {
        const response = await api.patch(
          '/requests/booster/9q2a62f751b62e00b31we2ceb2za0998/all',
          {
            toSecure: '66dthyt6grgrtfdsfsf',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const { data } = response;
        setData(data);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          signOut();
        }
      }
    }
    loadServices();
  }, [token, signOut]);

  return (
    <>
      {user && token && <ResumeBooster user={user} token={token} />}
      {user && token && (
        <TableServicesBoosters token={token} availableRequests={data.availableRequests} />
      )}
      <TableInProgressBoosters currentRequests={data.currentRequests} />
      <TableFinalizedBoosters finalizedRequests={data.finalizedRequests} />
    </>
  );
};

export default Booster;