import React, { useState } from "react";
import Request from "@assets/Table/Check.svg";
import FileSearch from "@assets/Table/FileSearch.svg";
import Going from "@assets/Table/Going.svg";
import PopupInfoFinished from "./PopupInfoFinished";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import { ServiceRequests } from "@utils/types";
import PopupPendingPayment from "./PopupPendingPayment";
import PopupFinalized from "./PopupFinalized";
import { useTranslation } from "react-i18next";

type Props = {
  token: string;
  data: Data;
};

type Data = {
  availableRequests: ServiceRequests[];
  currentRequests: ServiceRequests[];
  pendingRequests: ServiceRequests[];
  finalizedRequests: ServiceRequests[];
  pendingPaymentClientRequests: ServiceRequests[];
  pendingPaymentRequests: ServiceRequests[];
  cancelledRequests: ServiceRequests[];
  refundRequests: ServiceRequests[];
};

interface TableData {
  id: string;
  jogos: string[];
  server: string;
  servico: string;
  descricao: string;
  cliente: string;
  telefone?: string;
  discord?: string;
  valor: string | number;
  booster: string;
}

const maskPhone = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  return value.substring(0, 15);
};

const RequestFinishedAdm = (props: Props) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(1);
  const [selectedOption, setSelectedOption] = useState("optCurrent");
  const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [showPopupPendingPayment, setShowPopupPendingPayment] = useState(false);
  const [showPopupFinalized, setShowPopupFinalized] = useState(false);
  const [actionRequest, setActionRequest] = useState("");

  const [getPendingPaymentSelected, setPendingPaymentSelected] = useState("");

  const {
    availableRequests,
    currentRequests,
    pendingRequests,
    finalizedRequests,
    pendingPaymentClientRequests,
    pendingPaymentRequests,
    cancelledRequests,
    refundRequests,
  }: Data = props.data;

  const allRequests = [
    ...availableRequests,
    ...currentRequests,
    ...pendingRequests,
    ...finalizedRequests,
    ...pendingPaymentClientRequests,
    ...pendingPaymentRequests,
    ...cancelledRequests,
    ...refundRequests,
  ];

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClickInfo = (id: string) => {
    setShowPopupInfo((prevState) => !prevState);
    setActionRequest(id);
  };

  const handleButtonClickPendingPayment = (id: string) => {
    setPendingPaymentSelected(id);
    setShowPopupPendingPayment((prevState) => !prevState);
  };

  const handleButtonClickFinalized = (id: string) => {
    setPendingPaymentSelected(id);
    setShowPopupFinalized((prevState) => !prevState);
  };

  const handleCopyOnClick = (props: any) => {
    navigator.clipboard.writeText(props.target.innerHTML);
  };

  const handleRedirect = (id: string) => {
    window.location.href = `/dashboard/chat/${id}`;
  };

  function determinarServico(optionals: string, typeService: string): string {
    const isDuoBoost = optionals.split(",").includes("Duo Boost 🔥");

    if (isDuoBoost) {
      return typeService === "Elo Boost"
        ? "Duo Boost"
        : `${typeService} - Duo Boost`;
    }

    return typeService;
  }

  const sortData = (array: ServiceRequests[]): TableData[] => {
    return array.map((data) => ({
      id: data.id,
      jogos: [data.game_service],
      server: data.server,
      servico: determinarServico(data.optionals, data.type_service),
      descricao:
        data.type_service === "Vitórias"
          ? `${data.entry_level} - ${data.desired_level} vitória(s)`
          : data.type_service === "MD5"
            ? `${data.entry_level} - ${data.desired_level} partida(s)`
            : data.type_service === "Classificatória"
              ? `${data.entry_level} - ${data.desired_level} partida(s) - Classificatórias`
              : data.type_service === "Coach"
                ? `${data.entry_level} - ${data.desired_level} aula(s)`
                : data.type_service === "Premier Rank"
                  ? `${data.entry_level} - ${data.desired_level} rating(s)`
                  : `${data.entry_level} - ${data.desired_level}`,
      cliente: data.nick_game || 'Cliente',
      telefone: data.client ? (data.client.telephone || "") : "",
      discord: data.client ? data.client.discord : "",
      valor: data.price,
      booster: !data.booster ? "Booster" : data.booster.champion_name,
    }));
  };

  const getOrder = (id: string): ServiceRequests | undefined => {
    return allRequests.find((data) => data.id === id);
  };

  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
        <img src={Request} alt={Request} />
        {t("admin.requests")}
      </h2>
      <div className="hidden sm:grid">
        <div className="flex items-center justify-between py-4">
          <div className="w-full grid sm:flex items-center gap-8">
            <button
              className={`tab-button ${
                currentTab === 0
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
              onClick={() => handleTabChange(0)}
            >
              {t("admin.inqueue")}
            </button>
            <button
              className={`tab-button ${
                currentTab === 1
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
              onClick={() => handleTabChange(1)}
            >
              {t("table.currentTitle")}
            </button>
            <button
              className={`tab-button ${
                currentTab === 2
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
              onClick={() => handleTabChange(2)}
            >
              {t("admin.titleapproval")}
            </button>
            <button
              className={`tab-button ${
                currentTab === 3
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
              onClick={() => handleTabChange(3)}
            >
              {t("admin.titlepayment")}
            </button>
            <button
              className={`tab-button ${
                currentTab === 4
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
              onClick={() => handleTabChange(4)}
            >
              {t("table.finalized")}
            </button>
            <button
              className={`tab-button ${
                currentTab === 5
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
              onClick={() => handleTabChange(5)}
            >
              {t("admin.titlerefunded")}
            </button>
            <button
              className={`tab-button ${
                currentTab === 6
                  ? "bg-blue-1001 text-white"
                  : "bg-blue-20/40 text-white"
              } text-base font-semibold border border-secondary-500 p-4 rounded-lg`}
              onClick={() => handleTabChange(6)}
            >
              {t("admin.titlecanceled")}
            </button>
          </div>
        </div>
        <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
          {currentTab === 0 && (
            <div className="overflow-auto max-h-[570px]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      ID
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.game")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.server")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.service")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.description")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortData(availableRequests).map((data) => (
                    <tr
                      key={data.id}
                      onClick={() => handleRedirect(data.id)}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <div className="flex flex-row items-center justify-center">
                          <button
                            className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                            onClick={handleCopyOnClick}
                          >
                            {data.id}
                          </button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.jogos.map((jogo) => (
                          <span
                            key={jogo}
                            className="flex items-center justify-center"
                          >
                            {getGameIcon(jogo)}
                          </span>
                        ))}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.server}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.servico}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.descricao}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <button
                          className="p-2"
                          onClick={() => handleRedirect(data.id)}
                        >
                          <img src={Going} alt="Chat" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {currentTab === 1 && (
            <div className="overflow-auto max-h-[570px]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      ID
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.game")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      Booster
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.client")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.service")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.description")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortData(currentRequests).map((data) => (
                    <tr
                      key={data.id}
                      onClick={() => handleRedirect(data.id)}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <div className="flex flex-row items-center justify-center">
                          <button
                            className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                            onClick={handleCopyOnClick}
                          >
                            {data.id}
                          </button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.jogos.map((jogo) => (
                          <span
                            key={jogo}
                            className="flex items-center justify-center"
                          >
                            {getGameIcon(jogo)}
                          </span>
                        ))}
                      </td>
                      <td className="transition-colors duration-1000 text-gray-500 text-sm font-normal text-center px-4 py-4">
                        <div className="transition-colors duration-1000 p-2 px-4 font-semibold border border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 inline-flex rounded-full">
                          {data.booster}
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.cliente}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.servico}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.descricao}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <button
                          className="bg-[#FF8730] inline-flex items-center gap-3 text-gray-500 text-sm font-semibold px-4 py-2 rounded-full"
                          onClick={() => handleRedirect(data.id)}
                        >
                          <img src={Going} alt="Chat" />
                          Ver
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {currentTab === 2 && (
            <div className="overflow-auto max-h-[570px]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      ID
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      Booster
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.client")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.value")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.service")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.description")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortData(pendingRequests).map((data) => (
                    <tr
                      key={data.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <div className="flex flex-row items-center justify-center">
                          <button
                            className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                            onClick={handleCopyOnClick}
                          >
                            {data.id}
                          </button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-gray-500 text-sm font-normal text-center px-4 py-4">
                        <div className="transition-colors duration-1000 p-2 px-4 font-semibold border border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 inline-flex rounded-full">
                          {data.booster}
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.cliente}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        R$
                        {data.valor
                          .toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                          .replace(".", ",")}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.servico}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.descricao}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <button
                          className="p-2"
                          onClick={() => handleButtonClickInfo(data.id)}
                        >
                          <img src={FileSearch} alt="FileSearch" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {currentTab === 3 && (
            <div className="overflow-auto max-h-[570px]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      ID
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      Booster
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.client")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.value")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.service")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.description")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortData(pendingPaymentRequests).map((data) => (
                    <tr
                      key={data.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <div className="flex flex-row items-center justify-center">
                          <button
                            className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                            onClick={handleCopyOnClick}
                          >
                            {data.id}
                          </button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-gray-500 text-sm font-normal text-center px-4 py-4">
                        <div className="transition-colors duration-1000 p-2 px-4 font-semibold border border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 inline-flex rounded-full">
                          {data.booster}
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.cliente}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        R$
                        {data.valor
                          .toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                          .replace(".", ",")}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.servico}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.descricao}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <button
                          className="p-2"
                          onClick={() =>
                            handleButtonClickPendingPayment(data.id)
                          }
                        >
                          <img src={FileSearch} alt="FileSearch" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {currentTab === 4 && (
            <div className="overflow-auto max-h-[570px]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      ID
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      Booster
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.client")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.value")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.service")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.description")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortData(finalizedRequests).map((data) => (
                    <tr
                      key={data.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <div className="flex flex-row items-center justify-center">
                          <button
                            className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                            onClick={handleCopyOnClick}
                          >
                            {data.id}
                          </button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-gray-500 text-sm font-normal text-center px-4 py-4">
                        <div className="transition-colors duration-1000 p-2 px-4 font-semibold border border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 inline-flex rounded-full">
                          {data.booster}
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.cliente}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        R$
                        {data.valor
                          .toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                          .replace(".", ",")}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.servico}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.descricao}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <button
                          className="p-2"
                          onClick={() => handleButtonClickFinalized(data.id)}
                        >
                          <img src={FileSearch} alt="FileSearch" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {currentTab === 5 && (
            <div className="overflow-auto max-h-[570px]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      ID
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.game")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.service")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.description")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.client")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.phone")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      Discord
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortData(refundRequests).map((data) => (
                    <tr
                      key={data.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <div className="flex flex-row items-center justify-center">
                          <button
                            className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                            onClick={handleCopyOnClick}
                          >
                            {data.id}
                          </button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.jogos.map((jogo) => (
                          <span
                            key={jogo}
                            className="flex items-center justify-center"
                          >
                            {getGameIcon(jogo)}
                          </span>
                        ))}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.servico}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.descricao}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.cliente}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {maskPhone(data.telefone || "Não informado")}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.discord || "Não informado"}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <button
                          className="p-2"
                          onClick={() =>
                            handleButtonClickPendingPayment(data.id)
                          }
                        >
                          <img src={FileSearch} alt="FileSearch" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {currentTab === 6 && (
            <div className="overflow-auto max-h-[570px]">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      ID
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.game")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.service")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.description")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.client")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.phone")}
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      Discord
                    </th>
                    <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortData(refundRequests).map((data) => (
                    <tr
                      key={data.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                        isHovered ? "hovered-row" : ""
                      }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <div className="flex flex-row items-center justify-center">
                          <button
                            className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                            onClick={handleCopyOnClick}
                          >
                            {data.id}
                          </button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.jogos.map((jogo) => (
                          <span
                            key={jogo}
                            className="flex items-center justify-center"
                          >
                            {getGameIcon(jogo)}
                          </span>
                        ))}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.servico}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.descricao}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.cliente}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {maskPhone(data.telefone || "Não informado")}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {data.discord || "Não informado"}
                      </td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                        <button
                          className="p-2"
                          onClick={() =>
                            handleButtonClickPendingPayment(data.id)
                          }
                        >
                          <img src={FileSearch} alt="FileSearch" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {showPopupInfo && (
        <PopupInfoFinished
          token={props.token}
          request_id={actionRequest}
          showPopup={showPopupInfo}
          setShowPopup={setShowPopupInfo}
        />
      )}
      {showPopupPendingPayment && (
        <PopupPendingPayment
          token={props.token}
          infoService={getOrder(getPendingPaymentSelected)}
        />
      )}
      {showPopupFinalized && (
        <PopupFinalized
          token={props.token}
          infoService={getOrder(getPendingPaymentSelected)}
        />
      )}
    </div>
  );
};

export default RequestFinishedAdm;
