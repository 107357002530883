import Chat from '@components/Chat'
import { useAuth } from '@context/AuthContext'
import Loading from '@pages/loading'
import api from '@services/client/api'
import { IPedidos, ServiceRequests } from '@utils/types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {}

const ChatClient = (props: Props) => {
  const { token } = useAuth();

  let { id } = useParams<{ id: string }>()

  const [clientServiceData, setClientServiceData] = useState<IPedidos>()

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.patch(`/requests/search-by-id/6e1a87f751b12e4b310ecbeu2034018/${id}`, {
          toSecure: '66dthyt6grgrtfdsfsf'
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log(response.data)
        setClientServiceData(response.data);
      } catch (error: any) {
        toast.warn(error.response.data.message);
      }

    }

    loadData();
    window.scrollTo({ top: 0 })
  }, [id, token]);

  if (!clientServiceData) {
    return (
      <Loading/>
    )
  }

  return (
    <>
       <Chat serviceRequest={clientServiceData} />
    </>
  )
}

export default ChatClient
