import TableFinalizedClient from '@components/Dashboard/Client/finalized';
import ResumeClient from '@components/Dashboard/Client/resumeClient';
import TableServicesClient from '@components/Dashboard/Client/services';
import PopupTuor from '@components/Tuor';
import { useAuth } from '@context/AuthContext';
import Awaiting from '@pages/awaitingPayment';
import Loading from '@pages/loading';
import api from '@services/client/api';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

type Props = {};

type Payment = {
  id: string;
  client_id: string;
  checkout_id: string;
  payment_status: string;
  payment_method: string;
  service_id?: string;
  product_id?: string;
  created_at: Date;
};

const Client = (props: Props) => {
  const { user, token, signOut } = useAuth();
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [showPopupTour, setShowPopupTour] = useState(false);

  const [data, setData] = useState({
    availableRequests: [],
    currentRequests: [],
    finalizedRequests: [],
    inAbertRequests: [],
  });

  useEffect(() => {
    const tutorialCookie = Cookies.get('tutorial');
    if (!tutorialCookie) {
      setShowPopupTour(true);
      Cookies.set('tutorial', 'true', { expires: 365 });
    }
  }, []);

  useEffect(() => {
    async function loadServices() {
      try {
        const response = await api.patch(
          `/requests/client/2e2a61f761b62e5b310f2cfay2031010/all`,
          {
            toSecure: '66dthyt6grgrtfdsfsf',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const { data } = response;
        setData(data);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          signOut();
        }
      } finally {
        setLoading(false);
      }
    }
    loadServices();
  }, [token, signOut]);

  if (loading) {
    return <Loading />;
  }

  // if (!payments.find(item => item.payment_status === 'approved' || item.payment_status === 'paid')) {
  //   window.location.href = '/awaiting'
  // }

  return (
    <>
      {user && token && <ResumeClient user={user} token={token} />}
      <TableServicesClient
        availableRequests={data.availableRequests}
        currentRequests={data.currentRequests}
        finalizedRequests={data.finalizedRequests}
      />
      <TableFinalizedClient finalizedRequests={data.finalizedRequests} />
      {showPopupTour && user && token && <PopupTuor user={user} token={token} />}
    </>
  );
};

export default Client;