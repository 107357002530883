import Arrow from "@assets/Table/Arrow.svg";
import Process from "@assets/Table/HistoricTable.svg";
import { ServiceRequests } from "@utils/types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import IconAndamento from "@assets/Table/IconAndamento.svg";

type Props = {
  currentRequests: ServiceRequests[];
};

interface TableData {
  id: string;
  cliente: string;
  nick_game: string;
  idServico: string;
  descricao: string;
  servico: string;
  valor: string | number;
  status: string;
  imgClient: string;
  prazoEntrega: Date;
  current_level: string;
  desired_level: string;
}

const TableInProgressBoosters = ({ currentRequests }: Props) => {
  const { t } = useTranslation();

  const tableDataFeedback: TableData[] = currentRequests.map((data) => ({
    id: data.id,
    idServico: data.id,
    servico:
      data.preferences_md_or_duo_boost === "duo"
        ? "Duo Boost"
        : data.type_service,
    descricao:
      data.type_service === "Vitórias"
        ? `${data.entry_level} - ${data.level_course} vitória(s)`
        : data.type_service === "MD5"
        ? `${data.entry_level} - ${data.level_course} partida(s)`
        : data.type_service === "Classificatória"
        ? `${data.entry_level} - ${data.level_course} partida(s) - Classificatórias`
        : data.type_service === "Coach"
        ? `${data.entry_level} - ${data.quantity_classes_or_wins} aula(s)`
        : `${data.entry_level} - ${data.desired_level}`,
    cliente: data.client.nick_user || data.client.name,
    nick_game: data.nick_game,
    valor: data.price,
    status: data.request_status,
    imgClient: data.client.avatar,
    prazoEntrega: new Date(data.deadline),
    current_level: data.current_level,
    desired_level: data.desired_level,
  }));

  const renderStatus = (status: string) => {
    if (status === "EM ANDAMENTO") {
      return (
        <div className="bg-blue-1001 rounded-full w-auto inline-flex text-gray-500 font-semibold justify-center items-center px-4 py-2 gap-2">
          Em andamento
          <img src={IconAndamento} alt="Andamento" />
        </div>
      );
    } else if (status === "Pendente") {
      return <span className="text-gray-500 font-semibold">Pendente</span>;
    } else if (status === "FINALIZADO") {
      return <span className="text-gray-500 font-semibold">Pendente</span>;
    }
    return null;
  };

  const handleCopyOnClick = (props: any) => {
    navigator.clipboard.writeText(props.target.innerHTML);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Process} alt={Process} />
          {t("table.currentTitle")}
        </h2>
      </div>

      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
        <div className="overflow-auto max-h-[300px]">
          {tableDataFeedback.length === 0 ? (
            <p className="text-gray-100 font-semibold text-base text-center py-6">
              {t("table.nothingCurrent")}
            </p>
          ) : (
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    ID
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.client")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.description")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.service")}
                  </th>
                  {/* <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">Prazo de entrega</th> */}
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    Status
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2"></th>
                </tr>
              </thead>
              <tbody>
                {tableDataFeedback.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                      isHovered ? "hovered-row" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() =>
                      (window.location.href = `/dashboard/chat/${data.idServico}`)
                    }
                  >
                    <td className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-sm font-normal px-4 py-4 flex items-center justify-center">
                      <div className="rounded-full px-6 py-2 bg-blue-50 inline-flex items-center gap-3">
                        <button
                          className="overflow-hidden whitespace-nowrap max-w-[5em]"
                          onClick={handleCopyOnClick}
                        >
                          {data.idServico}
                        </button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                          />
                        </svg>
                      </div>
                    </td>
                    <td className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-sm font-light px-4 py-4 gap-2">
                      <div className="flex justify-center items-center">
                        {data.nick_game ? data.nick_game : 'Cliente'}
                      </div>
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.current_level}-{data.desired_level}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.servico}
                    </td>
                    {/* <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">{data.prazoEntrega.toLocaleDateString()}</td> */}
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        {renderStatus(data.status)}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <button className="p-2 border-none">
                        <img src={Arrow} alt={Arrow} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableInProgressBoosters;
