import React, { useEffect, useState } from 'react';
import None from '@assets/None.svg';
import api from '@services/client/api';
import axios from 'axios';

interface MatchHistoryProps {
  token: string;
  orderId: string;
  matchCount: number;
}

export interface Root {
  data: Daum[]
  meta: Meta
}

export interface Daum {
  players: any;
  id: string
  memo: any
  created_at: string
  game_map: string
  queue_info: QueueInfo
  version: string
  game_length_second: number
  is_remake: boolean
  is_opscore_active: boolean
  is_recorded: boolean
  average_tier_info: AverageTierInfo
  participants: Participant[]
  teams: Team[]
  myData: MyData
}

export interface QueueInfo {
  id: number
  queue_translate: string
  game_type: string
}

export interface AverageTierInfo {
  tier: string
  division: number
  tier_image_url: string
  border_image_url: string
}

export interface Participant {
  summoner: Summoner
  participant_id: number
  champion_id: number
  team_key: string
  position?: string
  items: number[]
  trinket_item: number
  rune: Rune
  spells: number[]
  stats: Stats
  tier_info: TierInfo
}

export interface Summoner {
  id: number
  summoner_id: string
  acct_id: string
  puuid: string
  game_name: string
  tagline: string
  name: string
  internal_name: string
  profile_image_url: string
  level: number
  updated_at: string
}

export interface Rune {
  primary_page_id: number
  primary_rune_id: number
  secondary_page_id: number
}

export interface Stats {
  champion_level: number
  damage_self_mitigated: number
  damage_dealt_to_objectives: number
  damage_dealt_to_turrets: number
  magic_damage_dealt_player: number
  physical_damage_taken: number
  physical_damage_dealt_to_champions: number
  total_damage_taken: number
  total_damage_dealt: number
  total_damage_dealt_to_champions: number
  largest_critical_strike: number
  time_ccing_others: number
  vision_score: number
  vision_wards_bought_in_game: number
  sight_wards_bought_in_game: number
  ward_kill: number
  ward_place: number
  turret_kill: number
  barrack_kill: number
  kill: number
  death: number
  assist: number
  largest_multi_kill: number
  largest_killing_spree: number
  minion_kill: number
  neutral_minion_kill_team_jungle: any
  neutral_minion_kill_enemy_jungle: any
  neutral_minion_kill: number
  gold_earned: number
  total_heal: number
  result: string
  op_score: number
  op_score_rank: number
  is_opscore_max_in_team: boolean
  op_score_timeline: OpScoreTimeline[]
  op_score_timeline_analysis: OpScoreTimelineAnalysis
}

export interface OpScoreTimeline {
  second: number
  score: number
}

export interface OpScoreTimelineAnalysis {
  left: string
  right: string
  last: string
}

export interface TierInfo {
  tier?: string
  division?: number
  lp?: number
  level?: number
  tier_image_url: string
  border_image_url?: string
}

export interface Team {
  key: string
  game_stat: GameStat
  banned_champions: number | undefined[]
}

export interface GameStat {
  dragon_kill: number
  baron_kill: number
  tower_kill: number
  is_remake: boolean
  is_win: boolean
  kill: number
  death: number
  assist: number
  gold_earned: number
}

export interface MyData {
  summoner: Summoner2
  participant_id: number
  champion_id: number
  team_key: string
  position?: string
  items: number[]
  trinket_item: number
  rune: Rune2
  spells: number[]
  stats: Stats2
  tier_info: TierInfo2
}

export interface Summoner2 {
  id: number
  summoner_id: string
  acct_id: string
  puuid: string
  game_name: string
  tagline: string
  name: string
  internal_name: string
  profile_image_url: string
  level: number
  updated_at: string
}

export interface Rune2 {
  primary_page_id: number
  primary_rune_id: number
  secondary_page_id: number
}

export interface Stats2 {
  champion_level: number
  damage_self_mitigated: number
  damage_dealt_to_objectives: number
  damage_dealt_to_turrets: number
  magic_damage_dealt_player: number
  physical_damage_taken: number
  physical_damage_dealt_to_champions: number
  total_damage_taken: number
  total_damage_dealt: number
  total_damage_dealt_to_champions: number
  largest_critical_strike: number
  time_ccing_others: number
  vision_score: number
  vision_wards_bought_in_game: number
  sight_wards_bought_in_game: number
  ward_kill: number
  ward_place: number
  turret_kill: number
  barrack_kill: number
  kill: number
  death: number
  assist: number
  largest_multi_kill: number
  largest_killing_spree: number
  minion_kill: number
  neutral_minion_kill_team_jungle: any
  neutral_minion_kill_enemy_jungle: any
  neutral_minion_kill: number
  gold_earned: number
  total_heal: number
  result: string
  op_score: number
  op_score_rank: number
  is_opscore_max_in_team: boolean
  op_score_timeline: OpScoreTimeline2[]
  op_score_timeline_analysis: OpScoreTimelineAnalysis2
}

export interface OpScoreTimeline2 {
  second: number
  score: number
}

export interface OpScoreTimelineAnalysis2 {
  left: string
  right: string
  last: string
}

export interface TierInfo2 {
  tier?: string
  division?: number
  lp?: number
  level?: number
  tier_image_url: string
  border_image_url?: string
}

export interface Meta {
  first_game_created_at: string
  last_game_created_at: string
}

interface ChampionImageProps {
  championId: string;
}

const MatchHistory = ({ token, orderId, matchCount }: MatchHistoryProps) => {
  const [data, setData] = useState<Root>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [champions, setChampions] = useState<Record<string, any>>({});

  useEffect(() => {
    const fetchMatchHistory = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/requests/history/match-history/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchChampionData = async () => {
      try {
        const response = await axios.get(
          'https://ddragon.leagueoflegends.com/cdn/14.3.1/data/en_US/champion.json'
        );
        setChampions(response.data.data);
      } catch (error) {
        console.error('Failed to fetch champion data', error);
      }
    };

    fetchMatchHistory();
    fetchChampionData();
  }, [orderId, matchCount, token]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error.message}</p>;
  if (!data) return <p>Sem dados disponíveis</p>;

  const getCurrentTime = () => new Date();

  const calculateTimeDifference = (matchCreatedAt: string) => {
    const matchTime = new Date(matchCreatedAt);
    const currentTime = getCurrentTime();
    const timeDifference = currentTime.getTime() - matchTime.getTime();
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(hoursDifference / 24);

    if (daysDifference > 0) {
      return `Há ${daysDifference} dia${daysDifference > 1 ? 's' : ''} atrás`;
    } else if (hoursDifference > 0) {
      return `Há ${hoursDifference} hora${hoursDifference > 1 ? 's' : ''} atrás`;
    } else {
      return 'Agora mesmo';
    }
  };

  const ChampionImage: React.FC<ChampionImageProps> = ({ championId }) => {
    const championKey = Object.keys(champions).find(
      (key) => champions[key].key === championId.toString()
    );
    const championName = championKey ? champions[championKey].id : 'unknown';
    const championImageURL = `https://opgg-static.akamaized.net/meta/images/lol/14.3.1/champion/${championName}.png`;

    return (
      <img
        src={championImageURL}
        alt={championName}
        className="w-full h-full rounded-xl object-cover mt-1"
      />
    );
  };


  return (
    <div>
      {Object.values(data.data).slice(0, 5).map((match, index) => (
        <div
        key={index}
        className={`${
          match.myData && match.myData.team_key
          ? match.teams.find(team => team.key === match.myData.team_key)?.game_stat.is_win
            ? 'bg-gradient-green'
            : 'bg-gradient-red'
          : 'bg-gradient-green' // Se não houver informação de equipe, definir como verde por padrão
        } mb-4 rounded-2xl px-4 py-2 border border-secondary-500 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6`}
      >

          <div className="grid sm:flex items-center gap-6">
            <div className="grid gap-1">
              <h2 className="text-gray-500 font-semibold text-sm">
                {match.queue_info?.queue_translate || 'Não Informado'}
              </h2>

              <h2 className="text-gray-100 font-semibold text-sm">
                {calculateTimeDifference(match.created_at)}
              </h2>

              <div className="flex items-center gap-2">

                {match.myData && (
                  <div className="flex items-center gap-2">
                    <h2 className={`font-semibold text-sm ${match.teams.find(team => team.key === match.myData.team_key)?.game_stat.is_win ? 'text-green-500' : 'text-red-500'}`}>
                      {match.teams.find(team => team.key === match.myData.team_key)?.game_stat.is_win ? 'WIN' : 'LOSE'}
                    </h2>
                  </div>
                )}

                <h2 className="transition-colors duration-1000 text-gray-500 font-semibold text-lg ">
                  {(match.game_length_second / 60).toFixed(2).replace('.', ':') || "00"}
                </h2>
              </div>
            </div>

            <div className="flex justify-center items-center">
              {match.myData &&
                <div className="flex items-start gap-2">
                  <div className="w-[68px] h-[68px] grid place-items-center">
                    <ChampionImage championId={match.myData.champion_id.toString()} />
                  </div>
                  <div className="grid">
                    <img
                      src={`https://lolcdn.darkintaqt.com/cdn/spells/${match.myData.spells[0]}`}
                      alt="Spell 1"
                      className="w-8 h-8 rounded-md object-cover"
                    />
                    <img
                      src={`https://lolcdn.darkintaqt.com/cdn/spells/${match.myData.spells[1]}`}
                      alt="Spell 2"
                      className="w-8 h-8 rounded-md object-cover mt-1"
                    />
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="grid sm:flex items-center gap-4">
            <div className="grid text-gray-500 font-normal text-sm text-center">
              {match.teams
                .filter(team => team.game_stat.is_win)
                .map((team, teamIndex) => (
                  <div key={teamIndex}>
                    <div className="flex items-center justify-center font-normal text-lg gap-2">
                      {match.myData.stats.kill || '0'}
                      <span className="text-gray-100"> / </span>
                      <span className="text-red-500"> {match.myData.stats.death || '0'} </span>
                      <span className="text-gray-100"> / </span>
                      {match.myData.stats.assist || '0'}
                    </div>
                    <h2>
                      <span className="text-orange-500 font-semibold">
                        {(team.game_stat.kill / team.game_stat.death).toFixed(2) || '00'}
                      </span>
                      &nbsp;
                      KDA
                    </h2>
                    <h2>
                      {match.myData.stats.minion_kill || '00'}
                      &nbsp;
                      CS
                    </h2>
                    <h2>
                      {match.myData.stats.vision_score || '00'}
                      &nbsp;
                      Vision
                    </h2>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="grid sm:flex items-center">
            <div className="max-w-[150px] flex flex-wrap gap-2">
              {match.myData.items.map((itemId, itemIndex) => (
                <div key={itemIndex} className="w-8 h-8">
                  <img
                    src={`https://ddragon.leagueoflegends.com/cdn/13.17.1/img/item/${itemId}.png`}
                    alt={`Item ${itemId}` || "Não Informado"}
                    className="w-8 h-8 rounded-md object-cover mt-1"
                  />
                </div>
              ))}

            </div>
          </div>

          <div className="grid justify-between items-center">
  <div className="grid justify-between grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-1">
    {match.participants.map((participant, participantIndex) => (
      <div key={participantIndex} className="flex items-center">
        <div className="w-6 h-6 mx-2">
          {participant && participant.champion_id && (
            <ChampionImage championId={participant.champion_id.toString()} />
          )}
        </div>
        <p className="text-gray-500 font-medium text-[10px] min-w-">
          {participant && participant.summoner && participant.summoner.name}
        </p>
      </div>
    ))}
  </div>
</div>

        </div>
      ))}
    </div>
  );

};

export default MatchHistory;