import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import IconAbandon from "@assets/Table/IconAbandon.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import api from "@services/client/api";
import { toast } from "react-toastify";
import WhiteSpin from "@assets/WhiteSpin.svg";

type Props = {
  token: string;
  serviceId: string;
};

const PopupAbandon = ({ token, serviceId }: Props) => {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const [selected, setSelected] = useState("");

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAbandonRequest = async (reason: string) => {
    if (!(reason.length > 0)) {
      toast.error(`Selecione uma das opções antes de prosseguir.`);
      return;
    }

    setLoading(true);

    try {
      await api.patch(
        `/requests/finalized/${serviceId}`,
        {
          reason,
          description: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
      setShowPopup(false);
      window.location.reload();
    }
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left">
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    Justifique o Abandono:
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <button
                className="flex items-center gap-2 cursor-pointer p-2 rounded-xl hover:opacity-70 focus:ring mt-8"
                onClick={() => setSelected("Abandono de Serviço")}
              >
                <img src={IconAbandon} alt="IconAbandon" />
                <h2 className="text-gray-500 text-base font-semibold">
                  {" "}
                  Abandonar Cliente{" "}
                </h2>
              </button>
              <button
                className="flex items-center gap-2 p-2 rounded-xl hover:opacity-70 focus:ring cursor-pointer mt-8"
                onClick={() =>
                  setSelected("Serviço finalizado por pedido do cliente")
                }
              >
                <img src={IconAbandon} alt="IconAbandon" />
                <h2 className="text-gray-500 text-base font-semibold">
                  {" "}
                  Concluir Serviço por Pedido do Cliente{" "}
                </h2>
              </button>
              <button
                className="flex items-center gap-2 p-2 rounded-xl hover:opacity-70 focus:ring cursor-pointer mt-8"
                onClick={() =>
                  setSelected(
                    "Serviço finalizado por banimento na conta por 14 dias"
                  )
                }
              >
                <img src={IconAbandon} alt="IconAbandon" />
                <h2 className="text-gray-500 text-base font-semibold">
                  {" "}
                  Concluir Serviço por Banimento na Conta por 14 dias{" "}
                </h2>
              </button>
              <button
                className="flex items-center gap-2 p-2 rounded-xl focus:ring hover:opacity-70 cursor-pointer mt-8"
                onClick={() =>
                  setSelected(
                    "Serviço finalizado por banimento permanente da conta"
                  )
                }
              >
                <img src={IconAbandon} alt="IconAbandon" />
                <h2 className="text-gray-500 text-base font-semibold">
                  {" "}
                  Concluir Serviço por Banimento Permanente na Conta{" "}
                </h2>
              </button>

              <div className="w-full flex items-end justify-end mt-8">
                <button
                  className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  disabled={loading}
                  onClick={() => handleAbandonRequest(selected)}
                >
                  {loading ? (
                    <>
                      <img
                        className="w-[24px] h-[24px] animate-spin"
                        src={WhiteSpin}
                        alt={"Spin"}
                      />
                      <p>Carregando...</p>
                    </>
                  ) : (
                    <>
                      <p>Enviar</p>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupAbandon;