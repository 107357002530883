import { ServiceRequests } from "@utils/types";
import { useNavigate } from "react-router-dom";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import Bag from "@assets/Table/Check.svg";
import Play from "@assets/Table/Play.svg";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import api from "@services/client/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {
  token: string;
  availableRequests: ServiceRequests[];
};

interface TableData {
  id: string;
  jogos: string[];
  servidor: string;
  servico: string;
  fila: string;
  elo: string;
  rotas: string[];
  campeoes: any;
  opcionais: string[];
  valor: string | number;
  imgClient: string;
  prazoEntrega: Date;
}

interface Champion {
  agentName: string;
  agentUuid: string;
}

const TableInProgressBoosters = ({ token, availableRequests }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [order, setOrder] = useState<TableData | null>(null);

  const tableDataFeedback = availableRequests.map((data) => {
    let champions: any | { agentName: string; agentUuid: string }[] = [];

    if (data.game_service === "Valorant") {
      let agents = data.champions_preference
        .replace(/[{}]/g, "")
        .split(",")
        .map((item) => item.replace(/"/g, ""));

      champions = agents.map((champion) => {
        const parts = champion.split("@");
        const agentUuid = parts[0];
        const agentName = parts[1];

        return {
          agentName,
          agentUuid,
        };
      });
    }
    if (data.game_service === "LOL") {
      if (
        data.champions_preference &&
        typeof data.champions_preference === "string"
      ) {
        champions = data.champions_preference
          .replace(/[{}]/g, "")
          .split(",")
          .map((item) => item.replace(/"/g, ""));
      } else {
        console.error("Invalid champions_preference data for LOL");
      }
    }

    const mappedData = {
      id: data.id,
      jogos: [data.game_service],
      servidor: data.server.toUpperCase(),
      servico:
        data.preferences_md_or_duo_boost === "duo"
          ? "Duo Boost"
          : data.type_service,
      fila: data.queue,
      elo:
        data.type_service === "Vitórias"
          ? `${data.entry_level} - ${data.level_course} vitória(s)`
          : data.type_service === "MD5"
          ? `${data.entry_level} - ${data.level_course} partida(s)`
          : data.type_service === "Classificatória"
          ? `${data.entry_level} - ${data.level_course} partida(s) - Classificatórias`
          : data.type_service === "Coach"
          ? `${data.entry_level} - ${data.quantity_classes_or_wins} aula(s)`
          : `${data.entry_level} - ${data.desired_level}`,
      rotas: [data.primary_route, data.secondary_route],
      campeoes: champions,
      opcionais: data.optionals.split(","),
      valor: data.price,
      imgClient: data.client.avatar,
      prazoEntrega: new Date(data.deadline),
    };

    return mappedData;
  });

  const headers = [
    t("table.game"),
    t("table.server"),
    t("table.service"),
    t("table.description"),
    ...(tableDataFeedback.some(
      (data) => data.jogos.includes("CSGO") || data.jogos.includes("Valorant")
    )
      ? []
      : [t("table.queue"), t("table.route")]),
    ...(tableDataFeedback.some((data) => data.jogos.includes("CSGO"))
      ? []
      : [t("table.champions")]),
    t("table.options"),
    t("table.value"),
    "",
  ];

  const setChampions = (game: string, champion: string | string[]) => {
    function contemItem(champion: string | string[]): boolean {
      const items: string[] = Array.isArray(champion) ? champion : [champion];
      if (items.length === 1) {
        if (items[0].length > 2) {
          return false;
        }
        return true;
      }
      return false;
    }

    if (contemItem(champion)) {
      return <h1>Sem preferência de campeões</h1>;
    }
    if (game === "LOL") {
      return (
        <>
          {Array.isArray(champion) ? (
            <>
              {champion.slice(0, 3).map((campeao: string, index: number) => (
                <div
                  key={campeao}
                  className="relative w-full"
                  style={{ left: `${index * -8}px` }}
                >
                  <img
                    src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${campeao}`}
                    alt={`${campeao}`}
                    className="block w-8 h-8 rounded-full object-cover border-2 border-gray-100/20"
                  />
                </div>
              ))}
              {champion.length > 3 && (
                <div
                  className="flex relative justify-center p-4 z-12 items-center bg-gray-70 text-white rounded-full w-8 h-8 border-2 border-gray-100/20"
                  style={{ marginLeft: "-24px" }}
                >
                  +{champion.length - 3}
                </div>
              )}
            </>
          ) : (
            <div>Error: Champion data is not an array</div>
          )}
        </>
      );
    } else if (game === "Valorant") {
      return (
        <>
          {Array.isArray(champion) ? (
            <>
              {champion
                .filter((agent: any) => {
                  return agent.agentUuid;
                })
                .slice(0, 3)
                .map((agent: any, index: number) => (
                  <div
                    key={agent.agentUuid}
                    className="relative w-full"
                    style={{ left: `${index * -8}px` }}
                  >
                    <img
                      src={`https://media.valorant-api.com/agents/${agent.agentUuid}/displayicon.png`}
                      alt={agent.agentName}
                      className="block w-8 h-8 rounded-full object-cover border-2 border-gray-100/20"
                    />
                  </div>
                ))}
              {champion.length > 3 && (
                <div
                  className="flex relative justify-center p-4 z-12 items-center bg-gray-70 text-white rounded-full w-8 h-8 border-2 border-gray-100/20"
                  style={{ marginLeft: "-24px" }}
                >
                  +{champion.length - 3}
                </div>
              )}
            </>
          ) : (
            <div>Error: Champion data is not an array</div>
          )}
        </>
      );
    }
  };

  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const handleMouseEnter = (id: string) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const [hoveredChampionId, setHoveredChampionId] = useState<string | null>(
    null
  );

  const handleChampionsMouseEnter = (id: string) => {
    setHoveredChampionId(id);
  };

  const handleChampionsMouseLeave = () => {
    setHoveredChampionId(null);
  };

  async function acceptRequest() {
    if (order) {
      try {
        const response = await api.patch(
          `/requests/accept/${order?.id}`,
          { data: "any" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { data } = response;
        toast.info(`Você aceitou o pedido ${data.ecommerce_id}!`);
        navigate(`/dashboard/chat/${data.id}`);
      } catch (error: any) {
        toast.error(error?.response.data.message);
      }
    }
  }

  const [showCaptchaForRow, setShowCaptchaForRow] = useState<string | null>(
    null
  );

  const invokeCaptcha = async (order: TableData) => {
    try {
      setOrder(order);
      setShowCaptchaForRow(order.id);
    } catch (error: any) {
      toast.error(error?.response.data.message);
    }
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Bag} alt={Bag} />
          {t("table.availableTitle")}
        </h2>
      </div>

      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
        <div className="overflow-auto max-h-[500px]">
          {tableDataFeedback.length === 0 ? (
            <p className="text-gray-100 font-semibold text-base text-center py-6">
              {t("table.nothingAvailable")}
            </p>
          ) : (
            <table className="table-auto w-full">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableDataFeedback.map((data) => (
                  <tr
                    key={data.id}
                    className="transition-colors duration-1000 bg-transparent h-full w-full"
                  >
                    <td className="text-gray-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 rounded-s-2xl">
                      {data.jogos.map((jogo) => (
                        <span
                          key={jogo}
                          className="flex items-center justify-center"
                        >
                          {getGameIcon(jogo)}
                        </span>
                      ))}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.servidor}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.servico}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.elo}
                    </td>
                    {data.jogos.includes("CSGO") ||
                    data.jogos.includes("Valorant") ? null : (
                      <>
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                          {data.fila}
                        </td>
                        <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                          {data.rotas.filter((rota) => rota).length > 0
                            ? data.rotas.filter((rota) => rota).join(", ")
                            : "Não informado"}
                        </td>
                      </>
                    )}

                    {data.jogos.includes("CSGO") ? null : (
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center flex justify-center items-center px-4 py-4 relative">
                        <div
                          className="flex justify-center items-center"
                          onMouseEnter={() =>
                            handleChampionsMouseEnter(data.id)
                          }
                          onMouseLeave={handleChampionsMouseLeave}
                        >
                          {data.jogos.map((jogo) => (
                            <span
                              key={jogo}
                              className="flex items-center justify-center"
                            >
                              {data.campeoes &&
                                setChampions(jogo, data.campeoes)}
                            </span>
                          ))}
                        </div>
                        {data.jogos.map((jogo) => (
                          <>
                            {hoveredChampionId === data.id && (
                              <div
                                className="fixed z-50 bottom-[35%] bg-blue-50 w-[200px] border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
                                onMouseEnter={() =>
                                  handleChampionsMouseEnter(data.id)
                                }
                                onMouseLeave={handleChampionsMouseLeave}
                              >
                                {jogo === "Valorant" ? (
                                  <>
                                    {data.campeoes
                                      .filter((agent: any) => agent.agentUuid)
                                      .map((agent: any, index: number) => (
                                        <div
                                          key={agent.agentUuid}
                                          className="flex items-center py-2 px-4 rounded-full"
                                        >
                                          <img
                                            src={`https://media.valorant-api.com/agents/${agent.agentUuid}/displayicon.png`}
                                            alt={agent.agentName}
                                            className="min-w-[32px] max-w-[32px] min-h-[32px] max-h-[32px] rounded-full object-cover border-2 border-gray-100/20"
                                          />
                                          <h3 className="text-gray-500 text-xs ml-2">
                                            {agent.agentName}
                                          </h3>
                                        </div>
                                      ))}
                                  </>
                                ) : (
                                  <>
                                    {data.campeoes.length === 0 ? (
                                      <h1>Nenhuma preferência de campeões</h1>
                                    ) : (
                                      <>
                                        {data.campeoes.map(
                                          (campeao: any, index: number) => (
                                            <div
                                              key={index}
                                              className="flex items-center py-2 px-4 rounded-full"
                                            >
                                              <img
                                                src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${campeao}`}
                                                alt={`${campeao}`}
                                                className="min-w-[32px] max-w-[32px] min-h-[32px] max-h-[32px] rounded-full object-cover border-2 border-gray-100/20"
                                              />
                                              <h3 className="text-gray-500 text-xs ml-2">
                                                {campeao.replace(".png", "")}
                                              </h3>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        ))}
                      </td>
                    )}

                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 relative">
                      <div
                        className="inline-flex border-2 border-primary-500 rounded-full p-4 px-6 cursor-pointer"
                        onMouseEnter={() => handleMouseEnter(data.id)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {data.opcionais && data.opcionais.length > 0
                          ? data.opcionais.length
                          : "0"}
                      </div>

                      {hoveredId === data.id && (
                        <div
                          className="fixed z-50 bg-blue-50 w-[200px] border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
                          onMouseEnter={() => handleMouseEnter(data.id)}
                          onMouseLeave={handleMouseLeave}
                        >
                          {data.opcionais.map((text, index) => (
                            <h3
                              key={index}
                              className="text-gray-500 text-xs py-2 px-4 rounded-full"
                            >
                              {text.trim()}
                            </h3>
                          ))}
                        </div>
                      )}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      R$
                      {data.valor
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace(".", ",")}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <button
                        className=" bg-orange-400 rounded-full w-8 h-8 p-2"
                        onClick={() => invokeCaptcha(data)}
                      >
                        <img src={Play} alt={Play} className="w-full h-full" />
                      </button>
                      <div className="absolute right-0 z-10">
                        {showCaptchaForRow === data.id && (
                          <ReCAPTCHA
                            sitekey="6LcryA0oAAAAAAs7VIqQK3s47FAG3Zzoe6HmK-nR"
                            onChange={() => acceptRequest()}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
export default TableInProgressBoosters;
