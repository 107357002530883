import ResumeAdm from "@components/Dashboard/mainDashboard/Adm/resumeAdm";
import RequestProgressAdm from "@components/Dashboard/mainDashboard/Adm/requestProgress";
import RequestFinishedAdm from "@components/Dashboard/mainDashboard/Adm/requestFinished";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { useEffect, useState } from "react";

const Admin = () => {
  const { user, token, signOut } = useAuth();

  const [data, setData] = useState({
    availableRequests: [],
    currentRequests: [],
    pendingRequests: [],
    finalizedRequests: [],
    pendingPaymentClientRequests: [],
    pendingPaymentRequests: [],
    cancelledRequests: [],
    refundRequests: [],
  });

  useEffect(() => {
    async function loadServices() {
      try {
        const response = await api.patch(
          "/requests/all/2e2a61f751b62e4b319e2cfeb2034018",
          {
            toSecure: "66dthyt6grgrtfdsfsf",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        setData(data);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          signOut();
        }
      }
    }
    loadServices();
  }, [token]);

  return (
    <>
      {user && token && <ResumeAdm user={user} token={token} />}
      {user && token && <RequestFinishedAdm token={token} data={data} />}
    </>
  );
};

export default Admin;
