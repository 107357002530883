import { useState } from "react";
import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import Check from "@assets/Table/HistoricTable.svg";
import IconAndamento from "@assets/Table/IconAndamento.svg";
import IconAvaliar from "@assets/Table/IconAvaliar.svg";
import IconMensagem from "@assets/Table/Message.svg";
import PopupInfoFeedback from "@components/Dashboard/mainDashboard/Adm/client/feedback/PopupInfoFeedback";
import { ServiceRequests } from "@utils/types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  availableRequests: ServiceRequests[];
  currentRequests: ServiceRequests[];
  finalizedRequests: ServiceRequests[];
};

interface TableData {
  id: string;
  idServico: string;
  descricao: string;
  servico: string;
  valor: string | number;
  jogos: string[];
  status: string;
}

const TableServicesClient = ({
  availableRequests,
  currentRequests,
  finalizedRequests,
}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [orderId, setOrderID] = useState("");
  const { t } = useTranslation();

  const availableServicesFilter: TableData[] = availableRequests.map(
    (data) => ({
      id: data.id,
      idServico: data.id,
      descricao:
        data.type_service === "Vitórias"
          ? `${data.entry_level} - ${data.level_course} vitória(s)`
          : data.type_service === "MD5"
          ? `${data.entry_level} - ${data.level_course} partida(s)`
          : data.type_service === "Classificatória"
          ? `${data.entry_level} - ${data.level_course} partida(s) - Classificatórias`
          : data.type_service === "Coach"
          ? `${data.entry_level} - ${data.quantity_classes_or_wins} aula(s)`
          : `${data.entry_level} - ${data.desired_level}`,
      servico:
        data.preferences_md_or_duo_boost === "duo"
          ? "Duo Boost"
          : data.type_service,
      valor: `R$${data.price}`,
      jogos: [data.game_service],
      status: data.request_status,
    })
  );

  const currentServicesFilter: TableData[] = currentRequests.map((data) => ({
    id: data.id,
    idServico: data.id,
    descricao:
      data.type_service === "Vitórias"
        ? `${data.entry_level} - ${data.level_course} vitória(s)`
        : data.type_service === "MD5"
        ? `${data.entry_level} - ${data.level_course} partida(s)`
        : data.type_service === "Coach"
        ? `${data.entry_level} - ${data.quantity_classes_or_wins} aula(s)`
        : `${data.entry_level} - ${data.desired_level}`,
    servico:
      data.preferences_md_or_duo_boost === "duo"
        ? "Duo Boost"
        : data.type_service,
    valor: `R$${data.price}`,
    jogos: [data.game_service],
    status: data.request_status,
  }));

  const finalizedRequestsFilter: TableData[] = finalizedRequests
    .filter((item) => item.is_feedback === false)
    .map((data) => ({
      id: data.id,
      idServico: data.id,
      descricao:
        data.type_service === "Vitórias"
          ? `${data.entry_level} - ${data.level_course} vitória(s)`
          : data.type_service === "MD5"
          ? `${data.entry_level} - ${data.level_course} partida(s)`
          : data.type_service === "Classificatória"
          ? `${data.entry_level} - ${data.level_course} partida(s) - Classificatórias`
          : data.type_service === "Coach"
          ? `${data.entry_level} - ${data.quantity_classes_or_wins} aula(s)`
          : `${data.entry_level} - ${data.desired_level}`,
      servico:
        data.preferences_md_or_duo_boost === "duo"
          ? "Duo Boost"
          : data.type_service,
      valor: `R$${data.price}`,
      jogos: [data.game_service],
      status: data.request_status,
    }));

  const servicesFiltered = availableServicesFilter
    .concat(currentServicesFilter)
    .concat(finalizedRequestsFilter);

  const handleButtonClick = (id: string) => {
    setOrderID(id);
    setShowPopup((prevState) => !prevState);
  };

  const renderStatus = (status: string, id: string) => {
    if (status === "FINALIZADO") {
      return (
        <span className="text-green-500 flex justify-center items-center gap-2">
          Concluído
          <button className="p-2" onClick={() => handleButtonClick(id)}>
            <img src={IconAvaliar} alt="Avaliar" />
          </button>
        </span>
      );
    } else if (status === "EM ANDAMENTO") {
      return (
        <div className="bg-blue-1001 rounded-full w-auto inline-block">
          <Link
            to={`/dashboard/chat/${id}`}
            className="text-gray-500 font-semibold flex justify-center items-center px-4 py-2 gap-2"
          >
            Em andamento
            <img src={IconAndamento} alt="Andamento" />
          </Link>
        </div>
      );
    } else if (status === "DISPONIVEL") {
      return (
        <span className="text-gray-100 flex justify-center items-center gap-2">
          Na fila
          <Link to={`/dashboard/chat/${id}`} className="p-2">
            <img src={IconMensagem} alt="Mensagem" />
          </Link>
        </span>
      );
    }
    return null;
  };

  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const handleCopyOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Impede que o clique no botão propague para o tr
    const button = event.target as HTMLButtonElement;
    const dataId = button.getAttribute("data-id");
    if (!dataId) return;

    navigator.clipboard.writeText(dataId);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Check} alt={Check} />
          {t("table.serviceTitle")}
        </h2>
      </div>

      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
        <div className="overflow-auto max-h-[300px]">
          {servicesFiltered.length === 0 ? (
            <p className="text-gray-100 font-semibold text-base text-center py-6">
              {t("table.nothingAvailable")}
            </p>
          ) : (
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    ID
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.game")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.service")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.description")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.value")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {servicesFiltered.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                      isHovered ? "hovered-row" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="flex flex-row justify-center items-center transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <button
                        className="overflow-hidden text-clip whitespace-nowrap max-w-[5em]"
                        onClick={handleCopyOnClick}
                        data-id={data.id}
                      >
                        {data.idServico.substring(0, 8)}
                      </button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.jogos.map((jogo) => (
                        <span
                          key={jogo}
                          className="flex items-center justify-center"
                        >
                          {getGameIcon(jogo)}
                        </span>
                      ))}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.servico || "Não Informado"}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.descricao || "Não Informado"}
                    </td>

                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.valor
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace(".", ",")}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      {renderStatus(data.status, data.idServico)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {showPopup && <PopupInfoFeedback orderId={orderId} />}
    </div>
  );
};

export default TableServicesClient;
