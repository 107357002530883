import React, { useEffect, useState, useTransition } from 'react'
import Close from '@assets/Buy/Close.svg'
import Client from '@assets/Table/Client.svg'
import Game from '@assets/Background/info-game.png'
import Check from '@assets/Info/IconCheck.svg'
import Info from '@assets/Info/IconInfo.svg'
import Reembolso from '@assets/Info/IconReembolso.svg'
import '@assets/Coockie.png'
import '@styles/popup.css'
import { ServiceRequests } from '@utils/types'
import api from '@services/client/api'
import Spin from '@assets/Spin.svg'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import PopUpApproveOrder from '../PopupApproveOrder'
import { useTranslation } from 'react-i18next'

type Props = {
  token: string;
  request_id: string;
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
};

type Booster = {
  avatar: string;
  name: string;
  currentElo: string;
  nextElo: string;
  phone: string;
  services: number;
};

interface TableData {
  booster: string;
  imgBooster: string;
  serviço: string;
  descrição: string;
  valor: string | number;
  comissão: number;
}

interface PopUpLoadingProps {
  showPopup: boolean;
}

const PopUpLoading = ({ showPopup }: PopUpLoadingProps) => {
  return (
    <>
    </>
  );
};

const PopupInfoFinished = ({
  token,
  request_id,
  showPopup,
  setShowPopup,
}: Props) => {
  const { t } = useTranslation();
  const [already, setAlready] = useState(false);
  const [showApprovePopUp, setShowApprovePopUp] = useState(false);

  const [order, setOrder] = useState<ServiceRequests>();
  const [booster, setBooster] = useState<Booster>();

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);

  const handleOpenImagePopup = () => {
    setIsImagePopupOpen(true);
  };

  const handleCloseImagePopup = () => {
    setIsImagePopupOpen(false);
  };

  const handleShowPopUpApproveOrder = () => {
    setShowApprovePopUp(!showApprovePopUp);
  };

  useEffect(() => {
    if (already) return;

    const getOrder = async () => {
      try {
        const responseOrder = await api.patch(
          `/requests/search-by-id/6e1a87f751b12e4b310ecbeu2034018/${request_id}`,
          {
            toSecure: "66dthyt6grgrtfdsfsf",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setOrder(responseOrder.data);

        const responseBooster = await api.get(
          `/users/${responseOrder.data.booster.user_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setBooster(responseBooster.data);
        setAlready(true);
      } catch (err) {
        if (err instanceof AxiosError) {
          toast(`Error: ${err.response?.data?.message}`);
        }

        toast(
          `Não foi possivel recuperar as informações do pedido ${request_id}.`
        );
        console.log(err);
        setShowPopup(false);
      }
    };
    getOrder();
  }, []);

  if (!already || !order) {
    return <PopUpLoading showPopup={showPopup} />;
  }

  const boostersData: Booster[] = [
    {
      avatar: !booster ? Client : booster.avatar,
      name: order.booster.champion_name,
      currentElo: "Warrior",
      nextElo: "Avançado",
      phone: order.booster.payment_key,
      services: order.booster.services_quantity,
    },
  ];

  const tableDataFeedback: TableData[] = [
    {
      booster: order.booster.champion_name,
      imgBooster: order.booster.champion_name,
      serviço: order.type_service,
      descrição: `${order.current_level} - ${order.desired_level}`,
      valor: order.price,
      comissão: order.booster.gain_percentage,
    },
  ];

  const handleAdminSetRefund = async () => {
    try {
      const response = await api.patch(
        `/requests/refund/${order.id}`,
        {
          typeRefund: "PARCIAL",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast(`O reembolso do pedido ${order.ecommerce_id} foi realizado.`);
      }
      setShowPopup(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast(`Error: ${err.response?.data?.message}`);
      }

      toast(`O pedido ${order.id} não pode ser reembolsado.`);
      console.log(err);
    }
  };

  const handleAdminInformation = () => {
    window.location.href = `/dashboard/chat/${order.id}`;
  };

  const handleBackToAvailable = () => {
    console.log("Botão 'Voltar aos Disponíveis' clicado!");
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2 flex items-center gap-2">
                    <img src={Client} alt={Client} />
                    <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                      {t("table.service")}:
                    </h2>
                      <h2 className="mr-2 text-gray-500 text-2xl font-normal">
                        {" "}
                        #{order.ecommerce_id}
                      </h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      className="bg-blue-20 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                      onClick={() => handleShowPopUpApproveOrder()}
                    >
                      <img src={Check} alt="Close" />
                      {t("tools.approve")}
                    </button>
                    <button
                      className="bg-blue-20 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                      onClick={() => handleAdminInformation()}
                    >
                      <img src={Info} alt="Close" />
                      {t("tools.information")}
                    </button>
                    <button
                      className="bg-blue-20 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                      onClick={() => handleBackToAvailable()}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.6054 2.00111 2.03446 5.54686 2.00224 9.94135C1.97002 14.3358 5.48858 17.9336 9.88269 17.9991C14.2768 18.0647 17.9011 14.5735 18 10.18V11.963V10C17.995 5.58378 14.4162 2.00496 10 2ZM11.45 14.9L6.5 9.95L11.45 5L12.864 6.414L9.328 9.95L12.863 13.486L11.451 14.9H11.45Z"
                          fill="#F53D3D"
                        />
                      </svg>

                      {t("tools.available")}
                    </button>

                    <button
                      className="bg-blue-20 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                      onClick={() => handleAdminSetRefund()}
                    >
                      <img src={Reembolso} alt="Close" />
                      {t("tools.repay")}
                    </button>
                    <button className="p-2" onClick={handleClosePopup}>
                      <img src={Close} alt="Close" />
                    </button>
                  </div>
                </div>
                <div className="bg-blue-50 rounded-xl p-4 max-h-[450px] overflow-y-auto mt-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
                    <div
                      className="rounded-xl h-56 cursor-pointer"
                      onClick={handleOpenImagePopup}
                    >
                      <img
                        src={`https://app-elomania-v2-uploads.s3.amazonaws.com/${order.screenshot_finished_service}`}
                        alt="Game"
                        className="w-full h-full rounded-xl"
                      />
                    </div>
                    {boostersData.slice(0, 3).map((booster, index) => (
                      <div
                        key={index}
                        className={`bg-blue-20 rounded-xl p-4 border border-secondary-500`}
                      >
                        <div className="flex items-center gap-2">
                          <div className="bg-blue-50 w-14 h-14 border border-primary-500 rounded-full">
                            <img
                              src={booster.avatar}
                              alt={booster.name}
                              className="w-full h-full rounded-full"
                            />
                          </div>
                          <h4 className="text-white font-light text-base">
                            {booster.name}
                          </h4>
                        </div>
                        <div className="mt-4">
                          <div className="flex items-center justify-between mt-8">
                            <p className="text-sm text-gray-500">
                              {booster.services}
                            </p>
                            <p className="text-sm text-gray-500">100</p>
                          </div>
                          <div className="h-2 bg-gray-300 rounded-full">
                            <div
                              className="h-full bg-primary-500 rounded-full"
                              style={{
                                width: `${(booster.services / 100) * 100}%`,
                              }}
                            ></div>
                          </div>
                          <div className="flex items-center justify-between mt-2">
                            <p className="text-gray-100 font-semibold text-xs">
                              {t("client.current")}:{" "}
                              <span className="text-gray-500">Warrior</span>
                            </p>
                            <p className="text-gray-100 font-semibold text-xs">
                              {t("client.next")}:{" "}
                              <span className="text-gray-500">Guardian</span>
                            </p>
                          </div>
                        </div>
                        <div className="mt-4 bg-primary-500 rounded-full p-2 text-center w-full">
                          <h4 className="text-white font-semibold text-sm">
                            {booster.phone}
                          </h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-blue-50 rounded-xl p-4 max-h-[450px] overflow-y-auto mt-4">
                  <div className="title w-full flex justify-between items-center">
                    <div className="text-left mb-2 flex items-center gap-2">
                      <img src={Client} alt={Client} />
                      <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                        Booster
                      </h2>
                    </div>
                  </div>
                  <div className="tab-content mt-4">
                    <div className="overflow-auto max-h-[300px]">
                      <table className="table-auto w-full">
                        <thead>
                          <tr>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                              Booster
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                              {t("table.service")}
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                              {t("table.description")}
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                              {t("table.value")}
                            </th>
                            <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                              {t("table.commission")}{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableDataFeedback.map((data) => (
                            <tr
                              key={data.booster}
                              className="transition-colors duration-1000 bg-transparent "
                            >
                              <td className="text-sm font-light text-center px-4 py-4 items-center justify-center flex gap-2">
                                <div className="imgProfile w-6 h-6 rounded-full bg-primary-500 border border-primary-500">
                                  <img
                                    src={data.imgBooster}
                                    alt={data.booster}
                                    className="w-full h-full rounded-full"
                                  />
                                </div>
                                {data.booster}
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                {data.serviço}
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                {data.descrição}
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                R$
                                {data.valor
                                  .toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                  .replace(".", ",")}
                              </td>
                              <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                                {data.comissão}%
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          {isImagePopupOpen && (
            <div
              onClick={handleCloseImagePopup}
              className="bg-opacity-70 fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
            >
              <div
                className="max-w-[90%] max-h-[90%]"
                onClick={handleCloseImagePopup}
              >
                <img
                  src={`https://app-elomania-v2-uploads.s3.amazonaws.com/${order.screenshot_finished_service}`}
                  alt="Game"
                  className="w-auto h-auto rounded border border-primary-500 backdrop-blur"
                />
              </div>
            </div>
          )}
          {showApprovePopUp && (
            <PopUpApproveOrder
              infoService={order}
              showPopUp={showApprovePopUp}
              handleClosePopUp={handleShowPopUpApproveOrder}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PopupInfoFinished;
