import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import Client from "@assets/Table/Client.svg";
import Info from "@assets/Info/IconInfo.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import { ServiceRequests } from "@utils/types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {
  token: string;
  infoService: ServiceRequests | undefined;
};

const PopupFinalized = ({ token, infoService }: Props) => {
  const [showPopup, setShowPopup] = useState(true);
  const { t } = useTranslation();

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (!infoService) {
    toast.error(
      `O pedido selecionado não foi encontrado. Tente atualizar a página antes de tentar novamente.`
    );
    setShowPopup(false);
  }

  const handleRedirect = (id: string) => {
    window.location.href = `/dashboard/chat/${id}`;
  };

  const maskPhone = (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value.substring(0, 15);
  };

  return (
    <>
      {showPopup && infoService && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Client} alt={Client} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    {t("table.service")}:
                  </h2>
                  <h2 className="mr-2 text-gray-500 text-2xl font-normal">
                    {" "}
                    #{infoService.ecommerce_id}
                  </h2>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    className="bg-blue-20 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                    onClick={() => handleRedirect(infoService.id)}
                  >
                    <img src={Info} alt="Info" />
                    {t("tools.more")}
                  </button>
                  <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <h2 className="mr-2 text-gray-100 text-base font-bold">
                  *{t("tools.more")}
                </h2>
                <div className="grid md:flex justiy-center items-center justify-between mt-4">
                  <div className="w-[48%]">
                    <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.clientname")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.client.name}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.phoneclient")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {maskPhone(
                          infoService.client.telephone || "Não informado"
                        )}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        Booster:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.booster.champion_name}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.boosternumber")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.booster.services_quantity}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between mt-4 pb-4">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.valueservice")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        R${" "}
                        {Number(infoService.price).toFixed(2).replace(".", ",")}
                      </h2>
                    </div>
                  </div>
                  {/* Informações Serviço */}
                  <div className="w-[48%]">
                    <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.link")}:
                      </h2>
                      <a
                        href={`https://www.op.gg/summoners/br/${infoService.client.nick_user}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-2 text-blue-100 text-base font-bold mt-4 underline"
                      >
                        Link
                      </a>
                    </div>
                    <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("table.service")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.type_service}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.elo")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {/* R${infoService.price} */}
                        {infoService.entry_level} - {infoService.desired_level}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.reason")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.reason_finalized_request ===
                        "servico finalizado"
                          ? "Serviço foi finalizado normalmente"
                          : infoService.reason_finalized_request}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between mt-4 pb-4">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("table.description")}:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.finalized_description
                          ? infoService.finalized_description
                          : "Sem descrição"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupFinalized;
