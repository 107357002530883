import React, { useState, useEffect } from "react";
import Close from "@assets/Buy/Close.svg";
import "@styles/popup.css";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import WhiteSpin from "@assets/WhiteSpin.svg";

type SoloTierInfo = {
  tier: string;
  division: number;
  lp: number;
  level: number | null;
  tier_image_url: string;
  border_image_url: string;
};

type SummonerData = {
  id: number;
  summoner_id: string;
  acct_id: string;
  puuid: string;
  game_name: string;
  tagline: string;
  name: string;
  internal_name: string;
  profile_image_url: string;
  level: number;
  updated_at: string;
  solo_tier_info: SoloTierInfo | null;
};

type ApiResponse = {
  data: SummonerData[];
};

type NickSelectionProps = {
  onNickSelected: (summoner: SummonerData) => void;
  order_id: string;
};

const NickSelection = ({ onNickSelected, order_id }: NickSelectionProps) => {
  const { token } = useAuth();
  const [nick, setNick] = useState("");
  const [suggestions, setSuggestions] = useState<SummonerData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [selectedSummoner, setSelectedSummoner] = useState<SummonerData | null>(null);
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        setLoading(true);
        
        const response = await api.post<ApiResponse>(
          `/requests/history/account-suggestions`,
          { nick: nick },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSuggestions(response.data.data);
      } catch (error) {
        console.error("Erro ao buscar sugestões de nick:", error);
      } finally {
        setLoading(false);
      }
    };

    if (nick.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [nick, token]);

  const handleNext = async () => {
    if (nick.trim() !== "") {
      try {
        const response = await api.patch(
          "/requests/summoner",
          {
            order: order_id,
            summoner: selectedSummoner,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (selectedSummoner) {
          onNickSelected(selectedSummoner); 
        }
        toast.success(`Invocador definido com sucesso!`);
      } catch (error) {
        console.error("Erro ao atualizar summoner:", error);
        toast.error(`Erro ao definir invocador!`);
      }
    }
  };

  const handleSelectItem = (summoner: SummonerData) => {
    setSelectedItem(summoner.id);
    setSelectedSummoner(summoner);
  };

  const isNextButtonDisabled = !nick.trim() || selectedItem === null;

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left">
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    Escolha o Nick:
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <div className="nick mt-4">
                <input
                  type="text"
                  id="nick"
                  placeholder="ex.: Porquinho Matador"
                  value={nick}
                  onChange={(e) => setNick(e.target.value)}
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
<div className="suggestions overflow-y-auto w-full max-h-80 grid pr-1">
 {suggestions ? suggestions.map((suggestion, index) => (
      <div
        key={suggestion.id}
        className={`suggestion-item bg-blue-20 p-4 rounded-md shadow-md flex items-start w-full mt-2 ${
          index === 0 ? "mt-2" : ""
        } ${index === suggestions.length - 1 ? "mb-2" : ""} ${
          selectedItem === suggestion.id ? "bg-primary-500/20" : ""
        }`}
        onClick={() => handleSelectItem(suggestion)}
      >
        <img
          src={suggestion.profile_image_url}
          alt={suggestion.game_name}
          className="w-16 h-16 object-cover rounded-full mr-4"
        />
        <div className="flex flex-col">
          <div className="flex flex-row gap-1.5">
            <span className="text-sm font-semibold mb-1 text-indigo-600">
              {suggestion.game_name}
            </span>
            <span className="text-gray-700">#{suggestion.tagline}</span>
          </div>
          <div className="flex items-center">
            <p className="text-xs text-gray-500 font-bold mr-4">
              Level: {suggestion.level}
            </p>
            {suggestion.solo_tier_info && (
              <div className="flex items-center">
                <img
                  src={suggestion.solo_tier_info.tier_image_url}
                  alt={suggestion.solo_tier_info.tier}
                  className="w-6 h-6 mr-1"
                />
                <p className="text-xs text-gray-500">
                  {suggestion.solo_tier_info.tier}{" "}
                  {suggestion.solo_tier_info.division} -{" "}
                  {suggestion.solo_tier_info.lp} LP
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    )) : null}
  {loading || suggestions.length < 5 ? (
    Array.from({ length: Math.max(5 - suggestions.length, 0) }).map((_, index) => (
      <div
        key={index}
        className=" bg-blue-20 animate-pulse p-4 rounded-md shadow-md flex items-start w-full mt-2"
      >
<svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="30" fill="#02061B" />
    </svg>
        <div className="flex flex-col w-full ml-4">
  <div className="flex flex-row gap-1.5">
    <span className="w-24 h-4 bg-blue-50 mb-1 rounded"></span>
    <span className="w-12 h-4 bg-blue-50 rounded"></span>
  </div>
  <div className="flex items-center">
    <p className="w-12 h-3 bg-blue-50 font-bold mr-4 rounded"></p>
    <div className="flex items-center">
      <div className="w-6 h-6 bg-blue-50 mr-1 rounded-full"></div>
      <p className="w-24 h-3 bg-blue-50 rounded"></p>
    </div>
  </div>
</div>

      </div>
    ))
  ) : null}
</div>

              </div>
              <div className="w-full flex items-end justify-end mt-4">
                <button
                  className={`flex flex-row space-x-2 rounded-full font-semibold ${
                    isNextButtonDisabled
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-blue-1001 text-white"
                  } px-4 py-3 rounded-lg shadow-inner transition-all duration-300`}
                  onClick={handleNext}
                  disabled={isNextButtonDisabled}
                >
                  <p>Próximo</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

type Props = {
  token: string;
  request_id: string;
};

type FormData = {
  nick: string;
  login: string;
  password: string;
};

const PopupDadosConta = ({ token, request_id }: Props) => {
  const [selectedSummoner, setSelectedSummoner] = useState<SummonerData | null>(null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [nick, setNick] = useState(selectedSummoner ? `${selectedSummoner.game_name} #${selectedSummoner.tagline}` : '');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ nick?: string, login?: string, password?: string }>({});

  const handleNickSelected = (summoner: SummonerData) => {
    setSelectedSummoner(summoner);
    setNick(`${summoner.game_name} #${summoner.tagline}`);
    setLogin('');
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const newErrors: { nick?: string, login?: string, password?: string } = {};

    if (!nick.trim()) newErrors.nick = "Digite o seu nick.";
    if (!login.trim()) newErrors.login = "Digite o seu login.";
    if (!password.trim()) newErrors.password = "Digite a sua senha.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      await api.post(
        `/requests/update-credentials`,
        {
          nick,
          login,
          senha: password,
          request_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.info(`As credenciais foram enviadas.`);
    } catch (err: any) {
      if (err) {
        toast.error(err?.response?.data?.message);
        return;
      }

      toast.error(
        `Não foi possivel enviar as credenciais. Tente novamente mais tarde.`
      );
      console.error(err);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  if (selectedSummoner) {
    return (
      <div className="bg-overlay">
        <div className={`fixed z-10 inset-6 flex justify-center items-center`}>
          <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
            <div className="title w-full flex justify-between items-center">
              <div className="text-left mb-2">
                <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                  Dados da Conta:
                </h2>
              </div>
              <button className="p-2" onClick={handleClosePopup}>
                <img src={Close} alt="Close" />
              </button>
            </div>
            <form onSubmit={onSubmit}>
              <div className="nick mt-4">
                <h2> Nick: </h2>
                <input
                  type="text"
                  id="nick"
                  value={nick}
                  onChange={(e) => setNick(e.target.value)}
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
                {errors.nick && (
                  <p className="mt-1 text-sm text-red-800">
                    {errors.nick}
                  </p>
                )}
              </div>
              <div className="email mt-4">
                <h2> Login: </h2>
                <input
                  type="text"
                  id="email"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
                {errors.login && (
                  <p className="mt-1 text-sm text-red-800">
                    {errors.login}
                  </p>
                )}
              </div>
              <div className="senha mt-4">
                <h2> Senha: </h2>
                <input
                  type="text"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-blue-20 border border-secondary-500 rounded-md mt-2 px-4 py-2 w-full outline-none text-base text-gray-500/40"
                />
                {errors.password && (
                  <p className="mt-1 text-sm text-red-800">
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="w-full flex items-end justify-end mt-4">
                <button
                  className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <img
                        className="w-[24px] h-[24px] animate-spin"
                        src={WhiteSpin}
                        alt={"Spin"}
                      />
                      <p>Carregando...</p>
                    </>
                  ) : (
                    <>
                      <p>Enviar</p>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <NickSelection onNickSelected={handleNickSelected} order_id={request_id} />
  );
};


export default PopupDadosConta;
