import IconLol from "@assets/Table/IconLol.svg";
import IconWR from "@assets/Table/IconWR.svg";
import IconCS from "@assets/Table/IconCS.svg";
import IconTFT from "@assets/Table/IconTFT.svg";
import IconValorant from "@assets/Table/IconValorant.svg";
import Check from "@assets/Table/IconPending.svg";
import Client from "@assets/Table/Client.svg";
import { useAuth } from "@context/AuthContext";
import { useEffect, useState } from "react";
import { ServiceRequests } from "@utils/types";
import api from "@services/client/api";
import "@styles/status.css";
import { useTranslation } from "react-i18next";

type Props = {
  finalizedRequests: ServiceRequests[];
};

interface TableData {
  id: string;
  cliente: string;
  ecommerceID: string;
  idServico: string;
  descricao: string;
  servico: string;
  valor: string | number;
  jogos: string[];
  status: boolean;
  imgClient: string;
  current_level: string;
  desired_level: string;
  isPaid: boolean;
}

const TableFinalizedBoosters = ({ finalizedRequests }: Props) => {
  const { t } = useTranslation();

  const tableDataFeedback: TableData[] = finalizedRequests.map((data) => ({
    id: data.id,
    ecommerceID: data.ecommerce_id,
    idServico: data.id,
    servico:
      data.preferences_md_or_duo_boost === "duo"
        ? "Duo Boost"
        : data.type_service,
    descricao:
      data.type_service === "Vitórias"
        ? `${data.entry_level} - ${data.level_course} vitória(s)`
        : data.type_service === "MD5"
        ? `${data.entry_level} - ${data.level_course} partida(s)`
        : data.type_service === "Classificatória"
        ? `${data.entry_level} - ${data.level_course} partida(s) - Classificatórias`
        : data.type_service === "Coach"
        ? `${data.entry_level} - ${data.quantity_classes_or_wins} aula(s)`
        : `${data.entry_level} - ${data.desired_level}`,
    cliente: data.client.name,
    valor: data.price,
    jogos: [data.game_service],
    status: data.finalized_by_admin,
    imgClient: data.client.avatar,
    current_level: data.current_level,
    desired_level: data.desired_level,
    isPaid: data.is_paid_admin,
  }));
  const getGameIcon = (game: string) => {
    switch (game) {
      case "LOL":
        return <img src={IconLol} alt="League of Legends" />;
      case "WR":
        return <img src={IconWR} alt="Wild Rift" />;
      case "TFT":
        return <img src={IconTFT} alt="Teamfight Tactics" />;
      case "Valorant":
        return <img src={IconValorant} alt="Valorant" />;
      case "CS2":
        return <img src={IconCS} alt="CS 2" />;
      default:
        return null;
    }
  };

  const renderStatus = (status: boolean) => {
    if (status) {
      return (
        <div className="bg-[#56BD79] p-2 px-4 rounded-full flex items-center justify-center gap-2">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.17 14.21L9.1275 12.1675C8.95333 11.9933 8.73167 11.9063 8.4625 11.9063C8.19333 11.9063 7.97167 11.9933 7.7975 12.1675C7.62333 12.3417 7.53625 12.5633 7.53625 12.8325C7.53625 13.1017 7.62333 13.3233 7.7975 13.4975L10.505 16.205C10.695 16.395 10.9167 16.49 11.17 16.49C11.4233 16.49 11.645 16.395 11.835 16.205L17.2025 10.8375C17.3767 10.6633 17.4638 10.4417 17.4638 10.1725C17.4638 9.90333 17.3767 9.68168 17.2025 9.5075C17.0283 9.33333 16.8067 9.24625 16.5375 9.24625C16.2683 9.24625 16.0467 9.33333 15.8725 9.5075L11.17 14.21ZM12.5 22C11.1858 22 9.95083 21.7505 8.795 21.2514C7.63917 20.7523 6.63375 20.0757 5.77875 19.2212C4.92375 18.3662 4.24703 17.3608 3.7486 16.205C3.25017 15.0492 3.00063 13.8142 3 12.5C3 11.1858 3.24953 9.95083 3.7486 8.795C4.24767 7.63917 4.92438 6.63375 5.77875 5.77875C6.63375 4.92375 7.63917 4.24703 8.795 3.7486C9.95083 3.25017 11.1858 3.00063 12.5 3C13.8142 3 15.0492 3.24953 16.205 3.7486C17.3608 4.24767 18.3662 4.92438 19.2212 5.77875C20.0762 6.63375 20.7532 7.63917 21.2524 8.795C21.7515 9.95083 22.0006 11.1858 22 12.5C22 13.8142 21.7505 15.0492 21.2514 16.205C20.7523 17.3608 20.0757 18.3662 19.2212 19.2212C18.3662 20.0762 17.3608 20.7532 16.205 21.2523C15.0492 21.7515 13.8142 22.0006 12.5 22Z"
              fill="white"
            />
          </svg>

          <span className="text-gray-500 font-semibold">
            {t("table.approved")}
          </span>
        </div>
      );
    } else {
      return (
        <div className="bg-[#ffb13c] p-2 px-4 rounded-full flex items-center justify-center gap-2">
          <div
            className="slow-spin h-6 w-6 rounded-full"
            style={{
              border: "2px dashed #FFFFFF",
            }}
          ></div>
          <span className="text-gray-500 font-semibold">Pendente</span>
        </div>
      );
    }
    return null;
  };

  <div></div>;

  const renderPagamento = (isPaid: boolean) => {
    if (isPaid) {
      return (
        <div className="bg-[#56BD79] p-2 px-4 rounded-full flex items-center justify-center gap-2">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1253 17.6996C10.0653 17.6996 9.25531 16.7796 9.14531 15.5996H6.94531C7.06531 17.7896 8.70531 19.0196 10.6253 19.4296V21.5996H13.6253V19.4496C15.5753 19.0796 17.1253 17.9496 17.1253 15.8996C17.1253 13.0596 14.6953 12.0896 12.4253 11.4996C10.1553 10.9096 9.42531 10.2996 9.42531 9.34961C9.42531 8.25961 10.4353 7.49961 12.1253 7.49961C13.9053 7.49961 14.5653 8.34961 14.6253 9.59961H16.8353C16.7653 7.87961 15.7153 6.29961 13.6253 5.78961V3.59961H10.6253V5.75961C8.68531 6.17961 7.12531 7.43961 7.12531 9.36961C7.12531 11.6796 9.03531 12.8296 11.8253 13.4996C14.3253 14.0996 14.8253 14.9796 14.8253 15.9096C14.8253 16.5996 14.3353 17.6996 12.1253 17.6996Z"
              fill="#FFFFFF"
            />
          </svg>
          <span className="text-gray-500 font-semibold">
            {t("table.received")}
          </span>
        </div>
      );
    } else {
      return (
        <div className="bg-[#ffb13c] p-2 px-4 rounded-full flex items-center justify-center gap-2">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6255 7.49961C14.4055 7.49961 15.0655 8.34961 15.1255 9.59961H17.3355C17.2655 7.87961 16.2155 6.29961 14.1255 5.78961V3.59961H11.1255V5.75961C10.7355 5.83961 10.3755 5.96961 10.0255 6.11961L11.5355 7.62961C11.8555 7.54961 12.2255 7.49961 12.6255 7.49961ZM5.59555 4.51961L4.18555 5.92961L7.62555 9.36961C7.62555 11.4496 9.18555 12.5896 11.5355 13.2796L15.0455 16.7896C14.7055 17.2796 13.9955 17.6996 12.6255 17.6996C10.5655 17.6996 9.75555 16.7796 9.64555 15.5996H7.44555C7.56555 17.7896 9.20555 19.0196 11.1255 19.4296V21.5996H14.1255V19.4496C15.0855 19.2696 15.9555 18.8996 16.5855 18.3296L18.8055 20.5496L20.2155 19.1396L5.59555 4.51961Z"
              fill="#FFFFFF"
            />
          </svg>
          <span className="text-gray-500 font-semibold">Pendente</span>
        </div>
      );
    }
    return null;
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Check} alt={Check} />
          {t("table.finalized")}
        </h2>
      </div>

      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
        <div className="overflow-auto max-h-[300px]">
          {tableDataFeedback.length === 0 ? (
            <p className="text-gray-100 font-semibold text-base text-center py-6">
              {t("table.nothingFinalized")}
            </p>
          ) : (
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    ID
                  </th>
                  {/* <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">Cliente</th> */}
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.description")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.service")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.value")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.game")}
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    Status
                  </th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">
                    {t("table.payment")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableDataFeedback.map((data) => (
                  <tr
                    key={data.id}
                    className={`cursor-pointer transition-colors duration-1000 bg-transparent ${
                      isHovered ? "hovered-row" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <div className="bg-blue-1001 text-gray-500 font-semibold rounded-full px-6 py-2 inline-flex flex-row justify-center items-center">
                        #{data.ecommerceID}
                      </div>
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.current_level}-{data.desired_level}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.servico}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      R$
                      {data.valor
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace(".", ",")}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      {data.jogos.map((jogo) => (
                        <span
                          key={jogo}
                          className="flex items-center justify-center"
                        >
                          {getGameIcon(jogo)}
                        </span>
                      ))}
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                      <div className="flex items-center justify-center gap-2">
                        {renderStatus(data.status)}
                      </div>
                    </td>
                    <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ">
                      <div className="p-2 px-4 rounded-full flex items-center justify-center">
                        {renderPagamento(data.isPaid)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableFinalizedBoosters;