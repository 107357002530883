import { useState } from "react";
import { toast } from "react-toastify";
import { QRCode } from "react-qrcode-logo";
import ImgGorjeta from "@assets/Chat/Gorjeta.svg";
import Close from "@assets/Buy/Close.svg";
import handleCopy from "@utils/handlers/copy";
import {
    Popover,
    PopoverHandler,
    PopoverContent,
} from "@material-tailwind/react";
import QR_Code from "./qrcode";

interface GorjetaComponentProps {
    order: any;
}

const GorjetaComponent: React.FC<GorjetaComponentProps> = ({ order }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [copied, setCopied] = useState(false);
    const [openPopover, setOpenPopover] = useState(false);

    // Verifica se há um booster associado
    const hasBooster = order?.booster;

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleCopyCode = async () => {
        try {
            handleCopy(order?.tip?.payload || "");
            setCopied(true);
            setOpenPopover(true);
            setTimeout(() => {
                setCopied(false);
                setOpenPopover(false);
            }, 2000);
        } catch (error) {
            console.error("Erro ao copiar código:", error);
        }
    };

    const handleCopyPaypal = async () => {
        try {
            if (paymentKey) {
                handleCopy(paymentKey);
                setCopied(true);
                setOpenPopover(true);
                setTimeout(() => {
                    setCopied(false);
                    setOpenPopover(false);
                }, 2000);
            } else {
                toast.error("Nenhum e-mail PayPal disponível para copiar.");
            }
        } catch (error) {
            toast.error("Erro ao copiar e-mail PayPal.");
        }
    };

    const isPaypal = order?.booster?.payment_key?.startsWith("paypal ");
    const paymentKey = isPaypal
        ? order?.booster?.payment_key.replace("paypal ", "")
        : order?.booster?.payment_key;

    return (
        <div className="h-full">
            {/* Renderiza o botão apenas se houver um booster */}
            {hasBooster && (
                <button
                    onClick={handleOpenPopup}
                    className="transition-colors duration-1000 w-full h-full bg-blue-1001 hover:bg-blue-100 text-gray-500 dark:text-white text-base font-semibold rounded-lg cursor-pointer py-9 mt-6 flex items-center justify-center gap-2"
                >
                    <img src={ImgGorjeta} alt={ImgGorjeta} />
                    Gorjeta ao Booster
                </button>
            )}
            {showPopup && (
                <div className="bg-overlay backdrop-blur-2xl">
                    <div className="fixed z-10 inset-6 flex justify-center items-center">
                        <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
                            <div className="title w-full flex justify-between items-start">
                                <div className="text-left">
                                    <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                                        Gorjeta ao Booster: {""}
                                        {order?.booster?.champion_name || "Não Informado"}
                                    </h2>
                                </div>
                                <button className="p-2" onClick={handleClosePopup}>
                                    <img src={Close} alt="Close" />
                                </button>
                            </div>
                            {isPaypal ? (
                                <div className="w-full flex justify-center items-center mt-6">
                                    <div className="w-1/2">
                                        <h2 className=" text-gray-100 text-center text-base font-normal mb-5">
                                            Veja abaixo o e-mail Paypal do booster responsável pelo
                                            serviço.
                                        </h2>
                                        <input
                                            type="text"
                                            value={paymentKey || "Não Informado"}
                                            className="outline-none text-center select-none bg-blue-20 flex flex-row w-full justify-center items-center p-3 border-2 border-gray-700 rounded-[5px] font-semibold text-sm text-slate-500"
                                            readOnly
                                        />
                                        <div className="w-full flex justify-center items-center mt-5">
                                            <Popover open={openPopover} placement="bottom">
                                                <PopoverHandler onClick={handleCopyPaypal}>
                                                    <button className="flex flex-row justify-center items-center p-[0.85em] bg-blue-20 rounded-[10px]">
                                                        {copied ? (
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M16 22H4C2.89543 22 2 21.1046 2 20V8H4V20H16V22ZM20 18H8C6.89543 18 6 17.1046 6 16V4C6 2.89543 6.89543 2 8 2H20C21.1046 2 22 2.89543 22 4V16C22 17.1046 21.1046 18 20 18ZM8 4V16H20V4H8ZM13 13.561L9.293 9.853L10.707 8.439L13 10.733L17.293 6.44L18.707 7.854L13 13.56V13.561Z"
                                                                    fill="#6C5FFB"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M13.9999 22H3.99995C3.46402 22.0186 2.94442 21.8139 2.56524 21.4347C2.18605 21.0555 1.98132 20.5359 1.99995 20V10C1.98132 9.46408 2.18605 8.94448 2.56524 8.5653C2.94442 8.18611 3.46402 7.98138 3.99995 8.00001H7.99995V4.00001C7.98132 3.46408 8.18605 2.94448 8.56524 2.5653C8.94442 2.18611 9.46402 1.98138 9.99995 2.00001H19.9999C20.5359 1.98138 21.0555 2.18611 21.4347 2.5653C21.8138 2.94448 22.0186 3.46408 21.9999 4.00001V14C22.0183 14.5358 21.8134 15.0553 21.4343 15.4344C21.0552 15.8135 20.5358 16.0183 19.9999 16H15.9999V20C16.0183 20.5358 15.8134 21.0553 15.4343 21.4344C15.0552 21.8135 14.5358 22.0183 13.9999 22ZM3.99995 10V20H13.9999V16H9.99995C9.46411 16.0183 8.94468 15.8135 8.56557 15.4344C8.18645 15.0553 7.98162 14.5358 7.99995 14V10H3.99995ZM9.99995 4.00001V14H19.9999V4.00001H9.99995Z"
                                                                    fill="#6C5FFB"
                                                                />
                                                            </svg>
                                                        )}
                                                    </button>
                                                </PopoverHandler>
                                                <PopoverContent className="p-2 bg-blue-50 border border-gray-700 bg-opacity-70 backdrop-blur-sm text-white rounded-md text-center">
                                                    Copiado com sucesso!
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <QR_Code
                                    qrCode={order?.tip?.payload || ""}
                                    copied={copied}
                                    handleCopyCode={handleCopyCode} openPopover={openPopover} />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GorjetaComponent;
