import { io } from "socket.io-client";
import React, { useEffect, useRef, useState } from "react";
import ImgGorjeta from "@assets/Chat/Gorjeta.svg";
import Client from "@assets/Table/Client.svg";
import Feedback from "@assets/Table/Feedback.svg";
import Send from "@assets/Chat/Send.svg";
import MiniBag from "@assets/Table/MiniBag.svg";
import ArrowDown from "@assets/Chat/ArrowDown.svg";
import PopupDadosConta from "./InfoDadosConta";
import { IPedidos, IUser, ServiceRequests } from "@utils/types";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";
import PopupConcluir from "./PopupConcluir";
import { AxiosError } from "axios";

import PopupAbandon from "./PopupAbandon";
import { useNavigate } from "react-router";
import AutoMessage from "./AutoMessage";
import MatchHistory from "./MatchesHistory";
import ServiceInfo from "./ServiceDetails";
import { useWebSocketContext } from "@hooks/WebSocketContext";
import { ProfileImage } from "./ProfileDetails";
import { format } from "currency-formatter";
import Gorjeta from "./PopupTip";

import { useTranslation } from "react-i18next";

const DateTimeFormatter = (date: Date) => {
  const formatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return formatter.format(date);
};

type Props = {
  serviceRequest: IPedidos;
};

interface Message {
  id: string;
  room: any;
  author: any;
  message: any;
  time: any;
}

const Chat = ({ serviceRequest }: Props) => {
  const { websocket } = useWebSocketContext();
  const { user, token } = useAuth();
  const [order, setOrder] = useState<IPedidos>(serviceRequest);
  const [username, setUsername] = useState("");
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasMessages, setHasMessages] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) return;

    if (
      user.type_permission === "CLIENT" ||
      user.type_permission === "BOOSTER"
    ) {
      setHasMessages(true);
    }
  }, [user]);

  const ChatMessage = ({
    message,
    isSelf,
    senderName,
    time,
  }: {
    message: string;
    isSelf: boolean;
    senderName: string;
    time: string;
  }) => {
    const hour = DateTimeFormatter(new Date(time));
    if (!user) return null;

    return (
      <div className={`grid ${isSelf ? "justify-end" : "justify-start"}`}>
        <div
          className={`flex flex-col mb-4 ${
            isSelf ? "items-end" : "items-start"
          }`}
        >
          <div
            className={`p-4 max-w-[400px] break-words ${
              user.type_permission === "ADMIN"
                ? isSelf
                  ? "bg-orange-500 text-white rounded-t-2xl rounded-es-2xl"
                  : senderName === "Booster"
                  ? "bg-primary-500 border border-secondary-500 rounded-t-2xl rounded-ee-2xl"
                  : "bg-blue-50 border border-secondary-500 rounded-t-2xl rounded-ee-2xl"
                : isSelf
                ? "bg-primary-500 text-white rounded-t-2xl rounded-es-2xl"
                : "bg-blue-50 border border-secondary-500 rounded-t-2xl rounded-ee-2xl"
            }`}
          >
            <p className="text-sm font-semibold">{senderName}</p>{" "}
            <p className="text-sm font-normal">{message}</p>{" "}
          </div>
          <p
            className={`text-sm mt-2 text-gray-100 font-light ${
              isSelf ? "text-right" : "text-left"
            }`}
          >
            {hour}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (messages.length > 0) {
      setHasMessages(true);
    }
  }, [messages]);

  const chatDiv = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupDados, setShowPopupDados] = useState(false);
  const [showPopupConcluir, setshowPopupConcluir] = useState(false);
  const [showPopupAbandon, setshowPopupAbandon] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleCopyPassword = () => {
    navigator.clipboard
      .writeText(order.password_game)
      .then(() => {
        alert("Senha copiada para a área de transferência!");
      })
      .catch((err) => {
        console.log("Algo deu errado", err);
      });
  };

  const handleButtonClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  const handleButtonClickDados = () => {
    setShowPopupDados((prevState) => !prevState);
  };

  const handleButtonClickAbandon = () => {
    setshowPopupAbandon((prevState) => !prevState);
  };

  const handleButtonClickConcluir = () => {
    setshowPopupConcluir((prevState) => !prevState);
  };

  useEffect(() => {
    if (!user) return;

    const loadMessages = async () => {
      try {
        const response = await api.get(`/messages-chat/${order.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data } = response;
        setMessages(data);

        const container = chatDiv.current;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }

        if (user.type_permission === "BOOSTER") {
          setUsername("Booster");
        } else if (user.type_permission === "ADMIN") {
          setUsername("Admin");
        } else {
          setUsername("Cliente");
        }
        websocket.socket.emit("join_room", order.id);
      } catch (error: any) {
        if (error instanceof AxiosError) {
          toast(`Error: ${error?.response?.data?.message}`);
        }

        toast(`As mensagens não puderam ser carregadas.`);
      }
    };
    loadMessages();
  }, []);

  const sendMessage = async () => {
    if (inputText.length < 1) return;

    const message: Message = {
      id: crypto.randomUUID(),
      room: order.id,
      author: username,
      message: inputText,
      time: new Date(),
    };

    websocket.socket.emit("send_message", { ...message });
  };

  useEffect(() => {
    const receiveMessage = () => {
      websocket.socket.on("receive_message", (packet: Message) => {
        if (!(packet.room === order.id)) return;

        setMessages((messages: Message[]) => [...messages, packet]);

        if (chatDiv.current) {
          chatDiv.current.scrollTop = chatDiv.current.scrollHeight;
        }
      });
      return () => websocket.socket.off("receive_message");
    };
    receiveMessage();
  }, []);

  const handleInputTextEvent = (e: any) => {
    setInputText(e.target.value);
  };

  const handleSendMessageEvent = () => {
    sendMessage();
    setInputText("");
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSendMessageEvent();
    }
  };

  const updateOnlineBooster = async (id: string) => {
    try {
      await api.patch(
        `/boosters/online/${id}`,
        { data: "any" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error: any) {
      toast.warn(error.response.data.message);
    }

    // setServiceStatus(!serviceStatus);
  };

  const maskPhone = (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value.substring(0, 15);
  };

  function base64ToJson(base64: any) {
    try {
      const text = atob(base64);
      const json = JSON.parse(text);
      return json;
    } catch (error) {
      return [];
    }
  }

  const agents = base64ToJson(order.champions_preference);

  function Champions() {
    let champions: any | { id: string; name: string; image: string }[] = agents;

    return champions.map((champion: any, index: number) => (
      <div key={index} className="w-8 h-8">
        <img
          src={`${champion.image}`}
          alt={`${champion.name}`}
          className="w-8 h-8 rounded-full object-cover mt-1"
        />
      </div>
    ));
  }

  return (
    <div>
      <div className="bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 grid sm:flex items-center justify-between">
        <div className="flex w-full items-center">
          {user && token && (
            <ProfileImage order={order} user={user} token={token} />
          )}
        </div>
        {order.request_status !== "FINALIZADO" && (
          <div className="flex items-center gap-2 mt-4 sm:mt-0">
            {((user && user.type_permission === "BOOSTER") ||
              (user && user.type_permission === "ADMIN")) && (
              <>
                {order.request_status === "DISPONIVEL" && (
                  <button
                    onClick={() => updateOnlineBooster(order.id)}
                    className="bg-orange-400 rounded-full outline-none text-center p-2 px-5 text-sm text-gray-500 font-semibold hover:opacity-90 active:bg-orange-900"
                  >
                    Iniciar Boosting
                  </button>
                )}
                {order.request_status === "EM ANDAMENTO" && (
                  <>
                    <button
                      className="bg-primary-350 rounded-full outline-none text-center p-2 px-5 text-sm text-gray-500 font-semibold hover:opacity-90 active:bg-[#56BD79]"
                      onClick={handleButtonClickConcluir}
                    >
                      {t("chat.complete")}
                    </button>
                    <button
                      className="bg-blue-20 rounded-full outline-none text-center py-3 px-5 text-sm text-gray-500 font-semibold hover:opacity-70"
                      onClick={handleButtonClickAbandon}
                    >
                      <svg
                        width="27"
                        height="8"
                        viewBox="0 0 27 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.964 4H23.036"
                          stroke="#FFFFFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.964 4H14.036"
                          stroke="#FFFFFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.964 4H4.036"
                          stroke="#FFFFFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </>
                )}
              </>
            )}
            {user && user.type_permission === "CLIENT" && (
              <button
                className="bg-orange-400 rounded-full outline-none text-center p-2 px-5 text-sm text-gray-500 font-semibold"
                onClick={handleButtonClickDados}
              >
                {order.password_game ? (
                  <p>Atualizar informações</p>
                ) : (
                  <p>Preencher agora</p>
                )}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="col-span-2 transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 mt-4">
          <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
            <img src={Feedback} alt={Feedback} />
            Chat
          </h2>
          <div className="transition-colors duration-1000 bg-transparent rounded-2xl mt-4">
            <div
              className="chat bg-white-100 dark:bg-blue-50 relative p-2 border-t border-secondary-500 min-h-[500px] max-h-[500px] overflow-y-auto"
              ref={chatDiv}
            >
              {user && user.type_permission === "CLIENT" && (
                <AutoMessage
                  name="Blitz Bot"
                  message="A Elomania agradece a preferência. Termine de preencher
            os dados da conta. Qualquer dúvida é só responder no
            chat que o Booster irá lhe ajudar. 🚀"
                />
              )}

              {user && user.type_permission === "BOOSTER" && (
                <AutoMessage
                  name="Ryze Bot"
                  message="Saudações, booster! Não troque contatos externos com o
            cliente. Qualquer tentativa de desvio de clientes é
            proibido. Bons games! 🚀"
                />
              )}

              {messages.length > 0 ? (
                <>
                  {messages.map((item) => (
                    <ChatMessage
                      key={item.id}
                      message={item.message}
                      isSelf={username === item.author}
                      senderName={item.author}
                      time={item.time}
                    />
                  ))}
                </>
              ) : (
                <>
                  {!hasMessages && (
                    <div className="w-full min-h-[450px] max-h-[450px] flex justify-center items-center">
                      Não há mensagens.
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="bg-blue-50 relative p-2 rounded-3xl border border-secondary-500 mt-4">
              <input
                type="text"
                value={inputText}
                disabled={order.request_status === "FINALIZADO"}
                onChange={handleInputTextEvent}
                className="bg-gray-500 rounded-full py-3 px-10 outline-none text-gray-20 text-sm font-semibold w-full"
                placeholder="Mensagem"
                onKeyUp={handleEnterKeyPress}
              />
              <button
                className="absolute top-6 right-6"
                onClick={handleSendMessageEvent}
              >
                <img src={ArrowDown} alt={Send} />
              </button>
            </div>
          </div>
        </div>
        {/* Detalhes do serviço */}
        <div className="col-span-1 transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 mt-4">
          <div className="flex items-center justify-between">
            <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
              <img src={MiniBag} alt={MiniBag} />
              {t("chat.serviceDetails")}
            </h2>
            <h2 className="text-gray-100 font-semibold text-sm">
              {" "}
              {t("chat.currentLP")}
              <span className="text-gray-500"> {order.actual_pdl} </span>{" "}
            </h2>
          </div>
          <div className="transition-colors duration-1000 bg-transparent rounded-2xl mt-4">
            <ServiceInfo
              serviceType={order.type_service}
              serviceInfo={{
                current_level: order.current_level,
                desired_level: order.desired_level,
                urlEntryLevel: order.urlEntryLevel,
                urlCurrentLevel: order.urlCurrentLevel,
                urlDesiredLevel: order.urlDesiredLevel,
              }}
            />
            {user && user.type_permission === "CLIENT" && token && (
              <Gorjeta order={order} user={user} token={token} />
            )}
          </div>
        </div>
      </div>

      {/* Informações adicionais */}
      <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 mt-4">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Client} alt={Client} />
          {t("chat.additionalInfo")}
        </h2>
        {/* Informações Conta */}
        {((user && user.type_permission === "BOOSTER") ||
          (user && user.type_permission === "ADMIN")) &&
          order.request_status === "EM ANDAMENTO" &&
          order.type_service !== "DUO BOOST 🔥" &&
          order.type_service !== "DUO BOOST" && (
            <>
              <div className="grid sm:flex items-start justify-between transition-colors duration-1000 bg-white-100 dark:bg-blue-20 rounded-2xl p-4 mt-4 gap-6">
                <div className="w-full sm:w-[49%]">
                  <h2 className="text-gray-100 font-semibold text-sm">
                    {" "}
                    {t("chat.login")}{" "}
                  </h2>
                  <input
                    type="text"
                    id="login"
                    value={order.user_game}
                    disabled={true}
                    className="bg-blue-50 border border-secondary-500 shadow-inner rounded-xl px-4 py-2 w-full outline-none text-base text-gray-500"
                  />
                </div>
                <div className="w-full sm:w-[49%]">
                  <h2 className="text-gray-100 font-semibold text-sm">
                    {" "}
                    {t("chat.password")}{" "}
                  </h2>
                  <div className="grid sm:flex">
                    <input
                      type="text"
                      id="password"
                      value={
                        showPassword
                          ? order.password_game
                          : "*".repeat(order.password_game.length)
                      }
                      readOnly
                      className="bg-blue-50 border border-secondary-500 shadow-inner rounded-xl px-4 py-2 w-full outline-none text-base text-gray-500"
                    />
                    <button
                      onClick={handleCopyPassword}
                      className="sm:ml-2 bg-primary-500 p-2 w-full mt-4 sm:mt-0 sm:w-1/5 h-full rounded-md"
                    >
                      {t("chat.copy")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        {/* Informações Serviço */}
        <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-20 rounded-2xl p-4 mt-4">
          <div className="grid sm:flex items-start justify-between gap-6">
            <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
              {" "}
              {t("chat.game")}: <br />
              <span className="font-light text-lg">
                {" "}
                {order.game_service}{" "}
              </span>{" "}
            </h2>
            {!(
              order.game_service === "CS2" || order.game_service === "Valorant"
            ) && (
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {" "}
                {t("chat.server")}: <br />
                <span className="font-light text-lg">
                  {order.server.toUpperCase()}{" "}
                </span>
              </h2>
            )}
            {order.game_service === "CS2" &&
              order.type_service === "Premier Rank" && (
                <>
                  <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {" "}
                    {t("chat.initialRating")}: <br />
                    <span className="font-light text-lg">
                      {" "}
                      {order.current_level}{" "}
                    </span>{" "}
                  </h2>
                  <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {" "}
                    {t("chat.finalRating")}: <br />
                    <span className="font-light text-lg">
                      {" "}
                      {order.desired_level}{" "}
                    </span>{" "}
                  </h2>
                </>
              )}
            {!(
              order.game_service === "Valorant" || order.game_service === "CS2"
            ) && (
              <>
                <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                  {" "}
                  {t("chat.queue")}: <br />
                  <span className="font-light text-lg">
                    {" "}
                    {order.queue}{" "}
                  </span>{" "}
                </h2>
                {order.game_service !== "Teamfight Tactics" && (
                  <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {" "}
                    {t("chat.routes")}: <br />
                    <span className="font-light text-lg">
                      {" "}
                      {order.primary_route && order.secondary_route
                        ? `${order.primary_route}/${order.secondary_route}`
                        : "Não há rotas selecionadas."}{" "}
                    </span>{" "}
                  </h2>
                )}

                {!(
                  order.game_service === "Wild Rift" ||
                  order.game_service === "Teamfight Tactics"
                ) && (
                  <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                    {" "}
                    Flash: <br />
                    <div className="border border-gray-100 dark:border-gray-500 inline-flex px-5 py-2 rounded-md items-start justify-start mt-2">
                      <span className="text-2xl font-semibold">
                        {" "}
                        {order.summoner_spells
                          ? order.summoner_spells === "flash_f"
                            ? "F"
                            : order.summoner_spells === "flash_d"
                            ? "D"
                            : order.summoner_spells
                          : "F"}{" "}
                      </span>{" "}
                    </div>
                  </h2>
                )}
              </>
            )}

            {!(
              order.game_service === "CS2" ||
              order.game_service === "Teamfight Tactics"
            ) && (
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {" "}
                {order.game_service === "Valorant"
                  ? "Agentes"
                  : t("chat.champions")}: <br />
                <div className="flex flex-wrap gap-1">
                  {order.champions_preference && <Champions />}
                </div>
              </h2>
            )}

            <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
              {t("chat.price")}: <br />
              <span className="text-gray-100 dark:text-gray-500 font-light text-lg">
                {" "}
                {format(Number(order.price), { code: "BRL" })}
              </span>
            </h2>
          </div>
        </div>
        {/* Informações Adicionais */}
        <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-20 rounded-2xl p-4 mt-4">
          <h2 className="text-gray-100 font-semibold text-sm">
            {" "}
            {t("chat.additionalServices")}{" "}
          </h2>
          <div className="w-full flex flex-wrap items-center gap-2 mt-1">
            {order.optionals.split(",").map((text, index) => {
              if (text.trim().length === 0) return null;
              return (
                <h3
                  key={index}
                  className="bg-gray-100 text-xs py-2 px-4 rounded-full"
                >
                  {text}
                </h3>
              );
            })}
          </div>
        </div>
        {/* Informações Histórico */}
        <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-20 rounded-2xl p-4 mt-4">
          <h2 className="text-gray-100 font-semibold text-sm">
            {" "}
            {t("chat.history")}{" "}
          </h2>
          <div className="historic w-full mt-4">
            {user && token && (
              <MatchHistory
                orderId={serviceRequest.id}
                matchCount={5}
                token={token}
              />
            )}
          </div>
        </div>
      </div>

      {/* Cliente */}
      {user && user.type_permission === "ADMIN" && (
        <>
          <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 mt-4">
            <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
              <img src={Client} alt={Client} />
              {t("chat.clientInfo")}
            </h2>

            <div className="bg-white-100 dark:bg-blue-20 rounded-2xl p-4 grid grid-cols-3 md:grid-cols-4 gap-6 mt-4">
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {t("chat.clientName")}: <br />
                <span className="font-normal text-blue-50 dark:text-gray-500">
                  {order.client.name}
                </span>
              </h2>
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {t("chat.purchaseDate")}: <br />
                <span className="font-normal text-blue-50 dark:text-gray-500">
                  {order && typeof order.request_ecommerce_at === "string"
                    ? new Date(
                        parseInt(order.request_ecommerce_at.split("-")[0], 10),
                        parseInt(order.request_ecommerce_at.split("-")[1], 10) -
                          1,
                        parseInt(order.request_ecommerce_at.split("-")[2], 10)
                      ).toLocaleDateString("pt-BR")
                    : "Data não disponível"}
                </span>
              </h2>
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {t("chat.clientPhone")}: <br />
                <span className="font-normal text-blue-50 dark:text-gray-500">
                  {" "}
                  {maskPhone(order.client.telephone || "")}{" "}
                </span>{" "}
              </h2>
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {t("chat.clientDiscord")}: <br />
                <span className="font-normal text-blue-50 dark:text-gray-500">
                  {order.client.discord}
                </span>
              </h2>
            </div>
          </div>
        </>
      )}

      {showPopupDados && user && token && (
        <PopupDadosConta token={token} request_id={order.id} />
      )}
      {showPopupConcluir && user && token && (
        <PopupConcluir token={token} infoService={order} />
      )}
      {showPopupAbandon && user && token && (
        <PopupAbandon token={token} serviceId={order.id} />
      )}
    </div>
  );
};

export default Chat;
