import styled from "styled-components";
import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import { IPedidos, ServiceRequests } from "@utils/types";
import api from "@services/client/api";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { AxiosError } from "axios";
import WhiteSpin from "@assets/WhiteSpin.svg";

type Props = {
  token: string;
  infoService: IPedidos;
};

export const ModalImg = styled.div`
  width: 240px;
  height: 180px;
  border: 1px dashed white;
  border-radius: 8px;
  align-self: center;
  display: flex;
  background-color: var(--blue-light);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;

  .icon-drop-section {
    width: 24px;
  }

  .image-drop-section {
    max-height: 100%;
  }

  .drop-section {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    padding: 4px 0 40px;
    overflow: hidden;
  }

  .drop-section::after {
    content: attr(data-label);
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    padding: 8px 8px;
    font-size: 1rem;
    text-align: center;
    color: var(--background);
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    white-space: nowrap;
  }

  p {
    font-size: 14px;
    color: white;
    font-weight: 600;
    font-family: "Fira Sans", sans-serif;
  }

  span {
    font-size: 16px;
    color: white;
    font-weight: 600;
    font-family: "Fira Sans", sans-serif;
  }
`;

const PopupConcluir = ({ token, infoService }: Props) => {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [selectedImage, setSelectedImage] = useState<any>();

  const handleOnDrop = (file: any) => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    setSelectedImage(file);
  };

  const handleEndService = async () => {
    setLoading(true);

    try {
      const data = new FormData();
      data.append("file", selectedImage);

      await api.patch(`/requests/file/${infoService.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error: any) {
      if (error instanceof AxiosError) {
        toast.error(`Error: ${error?.response?.data?.message}`);
      }

      toast.error(`O arquivo de imagem não pode ser enviado. Tente novamente.`);
      console.log(error);
    }

    try {
      await api.patch(
        `/requests/finalized/${infoService.id}`,
        {
          reason: "servico finalizado",
          description: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.info("O pedido foi finalizado.");
      window.location.href = "/dashboard";
    } catch (error: any) {
      if (error instanceof AxiosError) {
        toast.error(`Error: ${error?.response?.data?.message}`);
        return;
      }

      toast.error(`O pedido ${infoService.id} não pode ser finalizado.`);
      console.log(error);
    } finally {
      setShowPopup(false);
      window.location.reload();
    }

    setLoading(false);
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2">
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    Número do Serviço: {infoService.ecommerce_id}
                  </h2>
                </div>
                <button className="p-2" onClick={handleClosePopup}>
                  <img src={Close} alt="Close" />
                </button>
              </div>
              <h2 className="mr-2 text-gray-100 text-base font-bold mt-4">
                *Confira todas as informações antes de enviar
              </h2>
              <div className="flex justiy-center items-center justify-between mt-4">
                <div className="w-1/2 flex justify-center items-center">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      acceptedFiles.map((file) => handleOnDrop(file));
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <ModalImg
                        style={selectedImage && { border: "2px solid #E0E0E0" }}
                        {...getRootProps()}
                      >
                        {!selectedImage ? (
                          <>
                            <span>ADICIONAR IMAGEM</span>
                            <p className="text-xs">ARRASTE A IMAGEM AQUI</p>
                            <input {...getInputProps()} />
                          </>
                        ) : (
                          <div
                            className="drop-section"
                            data-label={selectedImage?.name}
                          >
                            <img
                              className="image-drop-section"
                              src={selectedImage?.preview}
                              alt={selectedImage?.name}
                            />
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </ModalImg>
                    )}
                  </Dropzone>
                </div>
                {/* Print */}
                {/*<div className="img bg-white/20 w-[48%] h-44 grid place-items-center border-dashed border-2 border-primary-500 rounded-2xl">
                      <div className="grid place-items-center">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="Selected"
                            className="w-full h-44 rounded-2xl"
                          />
                        ) : (
                          <>
                            <img src={Export} alt="Export" />
                            <h2 className="text-gray-500 text-sm font-bold">
                              Adicionar Imagem
                            </h2>
                          </>
                        )}
                      </div>
                      <input
                        type="file"
                        name=""
                        id=""
                        className="text-gray-500 text-sm font-bold cursor-pointer"
                        onChange={handleImageChange}
                      />
                    </div>*/}
                {/* Informações Serviço */}
                <div className="w-[48%]">
                  <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      Nome do Cliente
                    </h2>
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      {infoService.client.name}
                    </h2>
                  </div>
                  <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      Valor Pago
                    </h2>
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      R${infoService.price}
                    </h2>
                  </div>
                  <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      Elo Esperado
                    </h2>
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      {infoService.desired_level}
                    </h2>
                  </div>
                  <div className="flex items-center justify-between mt-4 pb-4 border-b border-secondary-500">
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      Sua Comissão
                    </h2>
                    <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                      {infoService.booster.gain_percentage} %
                    </h2>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-end justify-end mt-4">
                <button
                  className="bg-blue-1001 rounded-lg border border-gray-100/40 shadow-inner transition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                  onClick={handleEndService}
                >
                  {loading ? (
                    <>
                      <img
                        className="w-[24px] h-[24px] animate-spin"
                        src={WhiteSpin}
                        alt={"Spin"}
                      />
                      <p>Carregando...</p>
                    </>
                  ) : (
                    "Concluir"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupConcluir;
