import { useWebSocketContext } from "@hooks/WebSocketContext";
import api from "@services/client/api";
import { IPedidos } from "@utils/types";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import ImgGorjeta from "@assets/Chat/Gorjeta.svg";
import Close from "@assets/Buy/Close.svg";
import { QRCode } from "react-qrcode-logo";
import QR_Code from "../Gorjeta/qrcode";
import handleCopy from "@utils/handlers/copy";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import GorjetaComponent from "../Gorjeta";

interface ProfileImageProps {
  order: IPedidos;
  user: {
    type_permission: string;
  };
  token: string;
}

export type SocketData = {
  userId: string;
  userName: string;
  roomId: string;
};

export const Gorjeta: React.FC<ProfileImageProps> = ({
  order,
  user,
  token,
}) => {
  const { websocket } = useWebSocketContext();
  const [profile, setProfile] = useState({
    name: "",
    avatar: "",
    id: "",
    online: false,
    status: "",
    lastSeen: "",
  });

  useEffect(() => {
    return () => {
      if (order && order.id && websocket.socket) {
        websocket.socket.emit("leave_room", order.id);
      }
    };
  }, [order, websocket.socket]);

  useEffect(() => {
    if (websocket.roomInfo && websocket.roomInfo.length > 0) {
      const userInRoom = websocket.roomInfo.find(
        (user) => user.auth && user.auth.id === profile.id
      );

      if (userInRoom) {
        const { auth, online, lastSeen, status } = userInRoom;
        const now = moment();
        const lastSeenMoment = moment(lastSeen);

        const isSameDay = now.isSame(lastSeenMoment, "day");

        setProfile((prevProfile) => ({
          ...prevProfile,
          online: online || false,
          status: status,
          lastSeen: isSameDay
            ? lastSeenMoment.fromNow()
            : lastSeenMoment.format("DD [de] MMM [às] HH:mm"),
        }));
      }
    }
  }, [websocket.roomInfo, profile]);

  useEffect(() => {
    if (user.type_permission === "ADMIN") {
      setProfile({
        name: order?.nick_game || order?.client?.nick_user || "Cliente",
        avatar: `https://app-elomania-v2-uploads.s3.amazonaws.com/${order?.client?.avatar}`,
        id: order?.client?.id,
        online: false,
        status: "",
        lastSeen: "",
      });
    } else if (user.type_permission === "CLIENT") {
      if (order?.booster && order.booster.champion_name) {
        setProfile({
          name: order.booster.champion_name,
          avatar: `http://ddragon.leagueoflegends.com/cdn/11.24.1/img/champion/${order.booster.champion_name}.png`,
          id: order.booster.user_id,
          online: false,
          status: "",
          lastSeen: "",
        });
      } else {
        setProfile({
          name: "Aguarde até que um booster aceite seu serviço!",
          avatar: "",
          id: "",
          online: false,
          status: "",
          lastSeen: "",
        });
      }
    } else {
      setProfile({
        name: "Cliente",
        avatar: `https://app-elomania-v2-uploads.s3.amazonaws.com/${order?.client?.avatar}`,
        id: order?.client?.id,
        online: false,
        status: "",
        lastSeen: "",
      });
    }
  }, [order, user]);

  return (
    <div className="h-full">
      <GorjetaComponent order={order}/>
    </div>
  );
};

export default Gorjeta;
