import React, { ReactNode, useState, useEffect } from 'react';
import { Outlet, BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '@context/AuthContext';
import QueryParam from '@i18n/query';

import Dashboard_Layout from '@pages/dashboard';

import SignIn from '@pages/auth/signIn';

// Dashboard - Admin
import Admin from '@pages/dashboard/[admin]';
import ClientAdm from '@pages/dashboard/[admin]/client';
import FeedbackAdm from '@pages/dashboard/[admin]/client/feedback';
import BoostersAdm from '@pages/dashboard/[admin]/team/boosters';
import CandidatesAdm from '@pages/dashboard/[admin]/team/candidates';
import AdminsAdm from '@pages/dashboard/[admin]/team/admins';
import SmurfAdm from '@pages/dashboard/[admin]/smurf';
import Roulette from '@pages/dashboard/[admin]/roulette/roulette';
import CouponsAdm from '@pages/dashboard/[admin]/tools/coupons';
import DuoBoostAdm from '@pages/dashboard/[admin]/tools/duoBoost';
import ResultAdm from '@pages/dashboard/[admin]/results';
import PriceAdm from '@pages/dashboard/[admin]/tools/price';

// Dashboard - Booster
import Booster from '@pages/dashboard/[booster]';
import AccountsBooster from '@pages/dashboard/[booster]/accounts';
import HistoricBooster from '@pages/dashboard/[booster]/historic';

// Dashboard - Client
import Client from '@pages/dashboard/[client]';
import RouletteClient from '@pages/dashboard/[client]/roulette';
import SmurfClient from '@pages/dashboard/[client]/smurf';
import StreamClient from '@pages/dashboard/[client]/streaming';
import VipClient from '@pages/dashboard/[client]/vip';
import ChatClient from '@pages/dashboard/[client]/chat';

// Service
import api from '@services/client/api';
import Profile from '@pages/dashboard/profile';
import NotFound from '@pages/notFound';
import ManageAdm from '@pages/dashboard/[admin]/roulette/manage';
import ForgotPassword from '@pages/auth/forgotPassword';
import Loading from '@pages/loading';
import Awaiting from '@pages/awaitingPayment';
import Settings from '@pages/dashboard/[admin]/config';
import DashboardSelects from '@pages/dashboard/[client]/store';

interface PrivateRouteProps {
  children: ReactNode;
  redirectTo: string;
}

interface RoleRouteProps {
  redirectTo: string;
  children: ReactNode;
  userTypePerm: 'ADMIN' | 'CLIENT' | 'BOOSTER';
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, redirectTo }) => {
  const { loaded, user, token } = useAuth();

  if (loaded === false) {
    return <Loading />;
  }

  if (loaded && user && token) {
    return <>{children}</>;
  }

  return <Navigate to={redirectTo} />;
};

const userRoutes = [
  {
    type: 'ADMIN',
    path: '/dashboard',
    layout: <Dashboard_Layout userType="ADMIN" />,
    routes: [
      { path: '/dashboard', element: <Admin /> },
      { path: 'client', element: <ClientAdm /> },
      { path: 'feedback', element: <FeedbackAdm /> },
      { path: 'boosters', element: <BoostersAdm /> },
      { path: 'settings', element: <Settings /> },
      { path: 'candidates', element: <CandidatesAdm /> },
      { path: 'admins', element: <AdminsAdm /> },
      { path: 'smurf', element: <SmurfAdm /> },
      // { path: 'streaming', element: <StreamingAdm /> },
      { path: 'roulette', element: <Roulette /> },
      { path: 'manage', element: <ManageAdm /> },
      { path: 'price', element: <PriceAdm /> },
      { path: 'coupons', element: <CouponsAdm /> },
      { path: 'duoboost', element: <DuoBoostAdm /> },
      { path: 'result', element: <ResultAdm /> },
      { path: 'profile', element: <Profile /> },
      { path: 'chat/:id', element: <ChatClient /> },
    ],
  },
  {
    type: 'BOOSTER',
    path: '/dashboard',
    layout: <Dashboard_Layout userType="BOOSTER" />,
    routes: [
      { path: '/dashboard', element: <Booster /> },
      { path: 'accounts', element: <AccountsBooster /> },
      { path: 'historic', element: <HistoricBooster /> },
      { path: 'profile', element: <Profile /> },
      { path: 'chat/:id', element: <ChatClient /> },
    ],
  },
  {
    type: 'CLIENT',
    path: '/dashboard',
    layout: <Dashboard_Layout userType="CLIENT" />,
    routes: [
      { path: '/dashboard', element: <Client /> },
      { path: 'roulette', element: <RouletteClient /> },
      { path: 'lol', element: <DashboardSelects game={'lol'} /> },
      { path: 'wildrift', element: <DashboardSelects game={'wild'} /> },
      { path: 'valorant', element: <DashboardSelects game={'valorant'} /> },
      { path: 'teamfight', element: <DashboardSelects game={'tft'} /> },
      { path: 'csgo', element: <DashboardSelects game={'cs2'} /> },
      { path: 'streaming', element: <StreamClient /> },
      { path: 'smurfs', element: <SmurfClient /> },
      { path: 'rewards', element: <VipClient /> },
      { path: 'chat/:id', element: <ChatClient /> },
      { path: 'profile', element: <Profile /> },
      { path: 'awaiting', element: <Awaiting /> },
    ],
  },
];

const Routing = () => {
  const { loaded, user } = useAuth();

  if (loaded === false) {
    return <Loading />;
  }

  const userConfig = userRoutes.find((config) => config.type === user?.type_permission);

  return (
    <BrowserRouter>
      <Routes>
        {userConfig && (
          <Route
            path={userConfig.path}
            element={
              <PrivateRoute redirectTo="/auth/sign-in">
                {userConfig.layout}
                <QueryParam />
              </PrivateRoute>
            }
          >
            {userConfig.routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>
        )}

        <Route path="/auth/sign-in" element={<SignIn />} />
        <Route path="/awating" element={<Awaiting />} />

        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to={'/auth/sign-in'} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;