import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Client from '@assets/Table/Client.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '@services/client/api';
import { useAuth } from '@context/AuthContext';
import handleCopy from '@utils/handlers/copy';
import { useTranslation } from 'react-i18next';

interface TableData {
  id: string;
  servidor: string;
  user: string;
  password: string;
  nick: string;
  current_level: number;
  is_available: boolean;
}

const TableDuoBoostBooster = () => {
  const { t } = useTranslation();
  const [tableDataDuoBoost, setTableDataDuoBoost] = useState<TableData[] | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    async function loadAccounts() {
      try {
        const response = await api.get("/accountDuoboost/account/available", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setTableDataDuoBoost(response.data);
      } catch (error: any) {
        setTableDataDuoBoost([]);
      }
    }
    loadAccounts();
  }, [token]);

  const confirmAction = async () => {
    setIsLoading(true);
    try {
      const response = await api.patch(`/accountDuoboost/use-account/${selectedID}`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Conta reservada com sucesso!', { autoClose: 3000 });
      setIsLoading(false);
      setModalVisible(false);
    } catch (error: any) {
      toast.error(error.response.data.message, { autoClose: 3000 });
      setIsLoading(false);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const showPassword = (id: string) => {
    setModalVisible(true);
    setSelectedID(id);
  };

  const hidePassword = () => {
    setModalVisible(false);
    setSelectedID('');
  };

  return (
    <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500">
      <div className="flex items-center justify-between">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Client} alt={Client} />
          {t("table.mine")}
        </h2>
      </div>
      <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
          <div className="overflow-auto max-h-[550px]">
          {tableDataDuoBoost && tableDataDuoBoost.length === 0 ? (
            <p className="text-gray-100 font-semibold text-base text-center py-6">Nenhuma conta duo boost!</p>
          ) : (
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">{t("table.user")}</th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">Nick</th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">{t("table.password")}</th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">{t("table.level")}</th>
                  <th className="transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2">{t("table.available")}</th>
                </tr>
              </thead>
              <tbody>
                {tableDataDuoBoost ? (
                  tableDataDuoBoost.map((data) => (
                    <tr
                      key={data.id}
                      className={`cursor-pointer transition-colors duration-1000 bg-transparent ${isHovered ? "hovered-row" : ""
                        }`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">{data.user}</td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">{data.nick}</td>
                      <div className={`transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4 ${data.is_available ? 'cursor-pointer' : ''}`} onClick={data.is_available ? () => showPassword(data.id) : () => handleCopy(data.password)}>
                        <div className="flex justify-center items-start flex-row gap-4">
                          <p>
                            {data.password}
                          </p>
                          {data.is_available ? (
                            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.97 18.385L14.614 15.029C13.1661 15.6882 11.5908 16.0204 10 16.002C8.35988 16.0224 6.73671 15.6684 5.254 14.967C4.10468 14.4063 3.07264 13.6318 2.213 12.685C1.30049 11.7069 0.583297 10.5634 0.1 9.31601L0 9.00201L0.105 8.68601C0.82781 6.84231 2.04426 5.23318 3.621 4.03501L1 1.41401L2.413 0.00201416L19.382 16.971L17.972 18.385H17.97ZM5.036 5.45101C3.75792 6.34693 2.74865 7.57472 2.117 9.00201C3.47142 12.1269 6.59587 14.1087 10 14.002C11.0498 14.0106 12.0936 13.8416 13.087 13.502L11.287 11.702C10.8863 11.8984 10.4462 12.001 10 12.002C8.34746 11.9916 7.01037 10.6546 7 9.00201C7.00048 8.55478 7.10309 8.11356 7.3 7.71201L5.036 5.45101ZM17.852 12.612L16.46 11.221C17.0456 10.5589 17.5256 9.81046 17.883 9.00201C16.5304 5.87559 13.4047 3.89309 10 4.00201C9.753 4.00201 9.505 4.01101 9.265 4.02801L7.5 2.26101C8.32163 2.08525 9.15979 1.99841 10 2.00201C11.6401 1.98166 13.2633 2.33564 14.746 3.03701C15.8953 3.59775 16.9274 4.37221 17.787 5.31901C18.6991 6.29598 19.4163 7.43809 19.9 8.68401L20 9.00201L19.895 9.31801C19.4268 10.5361 18.7342 11.6555 17.853 12.618L17.852 12.612Z" fill="#2E3A59" />
                            </svg>
                          ) : (
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.9999 20H1.99995C1.46402 20.0186 0.944422 19.8139 0.565236 19.4347C0.186051 19.0555 -0.0186761 18.5359 -5.24687e-05 18V8.00001C-0.0186761 7.46408 0.186051 6.94448 0.565236 6.5653C0.944422 6.18611 1.46402 5.98138 1.99995 6.00001H5.99995V2.00001C5.98132 1.46408 6.18605 0.944483 6.56524 0.565297C6.94442 0.186112 7.46402 -0.018615 7.99995 8.56642e-06H17.9999C18.5359 -0.018615 19.0555 0.186112 19.4347 0.565297C19.8138 0.944483 20.0186 1.46408 19.9999 2.00001V12C20.0183 12.5358 19.8134 13.0553 19.4343 13.4344C19.0552 13.8135 18.5358 14.0183 17.9999 14H13.9999V18C14.0183 18.5358 13.8134 19.0553 13.4343 19.4344C13.0552 19.8135 12.5358 20.0183 11.9999 20ZM1.99995 8.00001V18H11.9999V14H7.99995C7.46411 14.0183 6.94468 13.8135 6.56557 13.4344C6.18645 13.0553 5.98162 12.5358 5.99995 12V8.00001H1.99995ZM7.99995 2.00001V12H17.9999V2.00001H7.99995Z" fill="#2E3A59" />
                            </svg>

                          )}
                        </div>

                      </div>

                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">{data.current_level}</td>
                      <td className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4">
                        <div
                          className={`w-4 h-4 flex justify-center items-center rounded-full m-auto ${data.is_available
                            ? "bg-[#56BD79] transition-all duration-300"
                            : "bg-red-500"
                            }`}
                        ></div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className='items-center justify-center'>
                      <Skeleton count={2} height={'2em'} width={'100%'} baseColor="#232630" highlightColor="#444" duration={3} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          </div>
        </div>

      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center">
          <div className="items-stretch bg-[#02061B] flex w-1/2 flex-col px-6 py-8 rounded-3xl relative">
            <div className="text-white text-center text-xl font-semibold leading-6 self-center">
              Aviso
              <button
                onClick={(e) => setModalVisible(false)}
                className="absolute top-2 right-2 text-white hover:text-gray-300 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="text-white text-opacity-50 text-center text-md w-3/3 mt-7">
              Após a conclusão do serviço, a conta retornará automaticamente à lista
              de contas disponíveis.
            </div>
            <div className="items-stretch flex w-full flex-col mt-2 px-6 py-5">
              <div
                className={`text-white text-center text-sm font-semibold leading-6 whitespace-nowrap justify-center items-center shadow-sm bg-indigo-500 px-16 py-2.5 rounded-lg cursor-pointer hover:bg-indigo-600`}
                onClick={confirmAction}
              >
                {isLoading ? "Carregando..." : "Confirmar"}
              </div>
              <div
                className="text-white text-opacity-50 text-center text-sm font-semibold leading-6 border justify-center items-center mt-4 px-16 py-4 rounded-lg border-solid border-slate-500 border-opacity-30 cursor-pointer hover:border-opacity-50"
                onClick={(e) => setModalVisible(false)}
              >
                Cancelar
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableDuoBoostBooster;
