import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "@assets/Logo.svg";
import "@styles/sidebar.css";
import { useTheme } from "@hooks/Theme";

import Resumo from "@assets/SideMenu/resumo.svg";
import Clientes from "@assets/SideMenu/clientes.svg";
import Equipe from "@assets/SideMenu/equipe.svg";
import Smurfs from "@assets/SideMenu/smurfs.svg";
import Entretenimento from "@assets/SideMenu/entretenimento.svg";
import Ferramentas from "@assets/SideMenu/ferramentas.svg";
import Resultados from "@assets/SideMenu/resultados.svg";
import Loja from "@assets/SideMenu/Loja.svg";
import Vip from "@assets/SideMenu/Vip.svg";
import History from "@assets/SideMenu/History.svg";

import SubmenuClient from "@assets/SideMenu/IconSubmenuClient.svg";
import SubmenuFeedbacks from "@assets/SideMenu/IconSubmenuFeedbacks.svg";
import SubmenuBooster from "@assets/SideMenu/IconSubmenuBooster.svg";
import SubmenuCand from "@assets/SideMenu/IconSubmenuCand.svg";
import SubmenuAdmins from "@assets/SideMenu/IconSubmenuAdmins.svg";
import SubmenuStreaming from "@assets/SideMenu/IconSubmenuStreaming.svg";
import SubmenuRoleta from "@assets/SideMenu/IconSubmenuRoleta.svg";
import SubmenuCupon from "@assets/SideMenu/IconSubmenuCupon.svg";
import SubmenuPrice from "@assets/SideMenu/IconSubmenuPrice.svg";
import SubmenuDuoBoost from "@assets/SideMenu/IconSubmenuDuoBoost.svg";
import SubmenuValorant from "@assets/SideMenu/SubmenuValorant.svg";
import SubmenuLol from "@assets/SideMenu/SubmenuLol.svg";
import SubmenuTFT from "@assets/SideMenu/SubmenuTFT.svg";
import SubmenuWR from "@assets/SideMenu/SubmenuWR.svg";
import SubmenuCsGo from "@assets/SideMenu/SubmenuCsGo.svg";

import IconDark from "@assets/SideMenu/IconDark.svg";
import IconLight from "@assets/SideMenu/IconLight.svg";

import { useTranslation } from "react-i18next";
import { useAuth } from "@context/AuthContext";

interface Menu {
  name: string;
  icon: string;
  submenus?: Menu[];
  url?: string;
  isPaid?: boolean;
}

interface MenusByUserType {
  [key: string]: Menu[];
}

interface NavigationProps {
  userType: string;
}

function Navigation({ userType }: NavigationProps) {
  const [selectedSubMenu, setSelectedSubMenu] = useState<Menu | null>(null);
  const [activeMenu, setMenuActive] = useState(false);
  const [isLightTheme, setIsLightTheme] = useState(false);
  const { toggleTheme } = useTheme();
  const { user } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  const menusByUserType: MenusByUserType = {
    ADMIN: [
      {
        name: "Resumo",
        icon: Resumo,
        url: "/dashboard",
      },
      {
        name: t("sidebar.clients"),
        icon: Clientes,
        submenus: [
          {
            name: t("sidebar.clients"),
            icon: SubmenuClient,
            url: "/dashboard/client",
          },
          {
            name: "Feedbacks",
            icon: SubmenuFeedbacks,
            url: "/dashboard/feedback",
          },
        ],
      },
      {
        name: t("sidebar.team"),
        icon: Equipe,
        url: "/dashboard/boosters",
        submenus: [
          {
            name: "Boosters",
            icon: SubmenuBooster,
            url: "/dashboard/boosters",
          },
          {
            name: t("sidebar.candidates"),
            icon: SubmenuCand,
            url: "/dashboard/candidates",
          },
          {
            name: "Admins",
            icon: SubmenuAdmins,
            url: "/dashboard/admins",
          },
        ],
      },
      {
        name: "Smurfs",
        icon: Smurfs,
        url: "/dashboard/smurf",
      },
      {
        name: t("sidebar.entertainment"),
        icon: Entretenimento,
        submenus: [
          // {
          //   name: 'Streaming',
          //   icon: SubmenuStreaming,
          //   url: '/dashboard/streaming',
          // },
          {
            name: t("sidebar.roulette"),
            icon: SubmenuRoleta,
            url: "/dashboard/roulette",
          },
          {
            name: t("sidebar.manage"),
            icon: SubmenuRoleta,
            url: "/dashboard/manage",
          },
        ],
      },
      {
        name: t("sidebar.tools"),
        icon: Ferramentas,
        submenus: [
          {
            name: t("sidebar.pricing"),
            icon: SubmenuPrice,
            url: "/dashboard/price",
          },
          {
            name: t("sidebar.cupons"),
            icon: SubmenuCupon,
            url: "/dashboard/coupons",
          },
          {
            name: "Duo Boost",
            icon: SubmenuDuoBoost,
            url: "/dashboard/duoboost",
          },
          {
            name: t("sidebar.general"),
            icon: SubmenuRoleta,
            url: "/dashboard/settings",
          },
        ],
      },
      {
        name: t("sidebar.results"),
        icon: Resultados,
        url: "/dashboard/result",
      },
    ],
    CLIENT: [
      {
        name: t("sidebar.resume"),
        icon: Resumo,
        url: "/dashboard",
        isPaid: false,
      },
      {
        name: t("sidebar.store"),
        icon: Loja,
        url: "/dashboard/lol",
        isPaid: false,
        submenus: [
          {
            name: "League of Legends",
            icon: SubmenuLol,
            url: "/dashboard/lol",
          },
          {
            name: "Wild Rift",
            icon: SubmenuWR,
            url: "/dashboard/wildrift",
          },
          {
            name: "Valorant",
            icon: SubmenuValorant,
            url: "/dashboard/valorant",
          },
          {
            name: "Teamfight Tactics",
            icon: SubmenuTFT,
            url: "/dashboard/teamfight",
          },
          {
            name: "Counter-strike 2",
            icon: SubmenuCsGo,
            url: "/dashboard/csgo",
          },
        ],
      },
      {
        name: t("sidebar.smurf"),
        icon: Smurfs,
        url: "/dashboard/smurfs",
        isPaid: true,
      },
      {
        name: t("sidebar.roulette"),
        icon: Entretenimento,
        url: "/dashboard/roulette",
        isPaid: true,
      },
      // {
      //   name: t('sidebar.entertainment'),
      //   icon: Entretenimento,
      //   isPaid: true,
      //   submenus: [
      //     {
      //       name: 'Streaming',
      //       icon: SubmenuStreaming,
      //       url: '/dashboard/streaming',
      //     },
      //   ],
      // },
      // {
      //   name: t('sidebar.roulette'),
      //   icon: SubmenuRoleta,
      //   url: '/dashboard/roulette',
      //   isPaid: true,
      // },
      // {
      //   name: t('sidebar.vip'),
      //   icon: Vip,
      //   url: '/dashboard/rewards',
      //   isPaid: true,
      // },
    ],
    BOOSTER: [
      {
        name: t("sidebar.resume"),
        icon: Resumo,
        url: "/dashboard",
      },
      {
        name: t("sidebar.accounts"),
        icon: Smurfs,
        url: "/dashboard/accounts",
      },
      {
        name: t("sidebar.historic"),
        icon: History,
        url: "/dashboard/historic",
      },
    ],
  };

  const handleSubMenuClick = (menuIndex: number) => {
    setSelectedSubMenu(menusByUserType[userType][menuIndex]);
  };

  const handleMenuClick = () => {
    setMenuActive(false);
  };

  const handleMenuLeave = () => {
    // setTimeout(() => {
    //   setSelectedSubMenu(null);
    // }, 1000);
    // setMenuActive(false);
  };

  const isSubmenusEmpty = (menu: Menu | null) => {
    return !menu || !menu.submenus || menu.submenus.length === 0;
  };

  const currentMenus = menusByUserType[userType];

  const filteredMenus = currentMenus.filter((menu) => {
    if (menu.isPaid !== undefined) {
      if (user && user.isPaid === false) {
        // return menu.isPaid === false;
        return true;
      } else {
        return true;
      }
    }
    return true;
  });

  useEffect(() => {
    // Determine the active menu and submenu based on the current route
    const currentMenus = menusByUserType[userType];
    const activeMenu = currentMenus.find(
      (menu) => menu.url === location.pathname
    );

    if (activeMenu) {
      setSelectedSubMenu(activeMenu);
    } else {
      // Check if the current route matches any submenu
      for (const menu of currentMenus) {
        const activeSubmenu = menu.submenus?.find(
          (submenu) => submenu.url === location.pathname
        );
        if (activeSubmenu) {
          setSelectedSubMenu(menu);
          break;
        }
      }
    }
  }, [location.pathname, userType]);

  const [activePage, setActivePage] = useState(""); // Adicione o estado para a página ativa

  useEffect(() => {
    const activeMenu = currentMenus.find(
      (menu) => menu.url === location.pathname
    );
    if (activeMenu) {
      setActivePage(activeMenu.url || "");
    } else {
      for (const menu of currentMenus) {
        const activeSubmenu = menu.submenus?.find(
          (submenu) => submenu.url === location.pathname
        );
        if (activeSubmenu) {
          setActivePage(menu.url || "");
          break;
        }
      }
    }
  }, [location.pathname, userType, currentMenus]);

  const getFullAvatarUrl = (avatar: string | null) => {
    if (
      avatar &&
      (avatar.startsWith("http://") || avatar.startsWith("https://"))
    ) {
      return avatar;
    }
    return avatar
      ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`
      : "";
  };

  return (
    <ul className="fixed left-0 top-0 z-[1000] w-[100px] h-full hidden sm:flex flex-col items-center justify-between">
      <li>
        <a href="https://www.elomania.com" className="sidebar-logo-container">
          <img src={Logo} alt={Logo} />
        </a>
      </li>

      <div className="flex flex-col items-center gap-10">
        {filteredMenus.map((menu, index) => (
          <li
            key={index}
            className={`li-item ${activePage === menu.url ? "hovered" : ""}`}
            style={{ "--order": index } as React.CSSProperties}
            onClick={() => {
              handleSubMenuClick(index);
            }}
          >
            <NavLink to={menu.url ?? "/dashboard"}>
              <React.Fragment>
                <div className="icon">
                  <img src={menu.icon} alt={menu.name} />
                </div>
              </React.Fragment>
            </NavLink>
            {/* Renderizar o tooltip apenas quando não houver submenu */}
            {!menu.submenus || menu.submenus.length === 0 ? (
              <div className="tooltip">{menu.name}</div>
            ) : (
              <div className="submenu-page flex flex-col items-start justify-center gap-2">
                {menu.submenus.map((submenu, subIndex) => (
                  <NavLink key={subIndex} to={submenu.url ?? "/dashboard"}>
                    <React.Fragment>
                      <span>{submenu.name}</span>
                    </React.Fragment>
                  </NavLink>
                ))}
              </div>
            )}
          </li>
        ))}
      </div>

      <div className="bg-gradient-to-b mb-3 from-[#02061B] via-[#101230] to-[#101230] rounded-md p-2 gap-3 border border-secondary-500 flex items-center">
        <button onClick={toggleTheme}>
          <img src={isLightTheme ? IconLight : IconDark} alt="Theme Icon" />
        </button>
        <button onClick={toggleTheme}>
          <img src={isLightTheme ? IconDark : IconLight} alt="Theme Icon" />
        </button>
      </div>
    </ul>
  );
}

export default Navigation;
