import { useWebSocketContext } from "@hooks/WebSocketContext";
import api from "@services/client/api";
import { IPedidos } from "@utils/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";

interface ProfileImageProps {
  order: IPedidos;
  user: {
    type_permission: string;
  };
  token: string;
}

export type SocketData = {
  userId: string;
  userName: string;
  roomId: string;
};

export const ProfileImage: React.FC<ProfileImageProps> = ({
  order,
  user,
  token,
}) => {
  const { websocket } = useWebSocketContext();
  const [imageUrl, setImageURL] = useState("");
  const [userName, setUsername] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [editedNick, setEditedNick] = useState("");
  const [profile, setProfile] = useState({
    name: "",
    avatar: "",
    id: "",
    online: false,
    status: "",
    lastSeen: "",
  });

  useEffect(() => {
    return () => {
      if (order && order.id && websocket.socket) {
        websocket.socket.emit("leave_room", order.id);
      }
    };
  }, [order, websocket.socket]);

  useEffect(() => {
    if (websocket.roomInfo && websocket.roomInfo.length > 0) {
      const userInRoom = websocket.roomInfo.find(
        (user) => user.auth && user.auth.id === profile.id
      );

      if (userInRoom) {
        const { auth, online, lastSeen, status } = userInRoom;
        const now = moment();
        const lastSeenMoment = moment(lastSeen);

        const isSameDay = now.isSame(lastSeenMoment, "day");

        setProfile((prevProfile) => ({
          ...prevProfile,
          online: online || false,
          status: status,
          lastSeen: isSameDay
            ? lastSeenMoment.fromNow()
            : lastSeenMoment.format("DD [de] MMM [às] HH:mm"),
        }));
      }
    }
  }, [websocket.roomInfo, profile]);

  useEffect(() => {
    const getAvatarUrl = (avatar: any) => {
      if (avatar && avatar.startsWith('https')) {
        return avatar;
      } 
      return `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`;
    };

    if (user.type_permission === "ADMIN") {
      setProfile({
        name: order?.nick_game || "Cliente",
        avatar: getAvatarUrl(order?.client?.avatar),
        id: order?.client?.id,
        online: false,
        status: "",
        lastSeen: "",
      });
    } else if (user.type_permission === "CLIENT") {
      if (order?.booster && order.booster.champion_name) {
        setProfile({
          name: order.booster.champion_name,
          avatar: `http://ddragon.leagueoflegends.com/cdn/11.24.1/img/champion/${order.booster.champion_name}.png`,
          id: order.booster.user_id,
          online: false,
          status: "",
          lastSeen: "",
        });
      } else {
        setProfile({
          name: "Aguarde até que um booster aceite seu serviço!",
          avatar: "",
          id: "",
          online: false,
          status: "",
          lastSeen: "",
        });
      }
    } else {
      setProfile({
        name: order?.nick_game || "Cliente",
        avatar: getAvatarUrl(order?.client?.avatar),
        id: order?.client?.id,
        online: false,
        status: "",
        lastSeen: "",
      });
    }
  }, [order, user]);


  const handleEditClick = () => {
    if (user.type_permission === "ADMIN") {
      setEditMode(true);
    }
  };

  const handleConfirmClick = () => {
    const requestData = {
      nick_game: editedNick,
      request_id: order.id,
    };

    api
      .post("/requests/update-nick-admin", requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsername(editedNick);
        toast.success("Nome de usuário atualizado com sucesso!");
        setEditMode(false);
      })
      .catch((error: any) => {
        toast.error(error);
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleConfirmClick();
    }
  };


  return (
    <div className="bg-transparent h-10 gap-2 rounded-full flex items-center">
      {order?.booster?.champion_name?.length > 0 ? (
        <img
          src={profile.avatar}
          alt={profile.name}
          className="w-12 h-12 rounded-full"
        />
      ) : null}
      {isEditMode ? (
        <div className="flex items-center gap-2">
          <input
            type="text"
            value={editedNick}
            defaultValue={editedNick}
            onChange={(e) => setEditedNick(e.target.value)}
            onKeyDown={handleKeyDown}
            className="bg-blue-20 text-gray-500 font-semibold text-base ml-4 focus:outline-none rounded-full px-4 py-2"
          />
          <button onClick={handleConfirmClick}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L10 16L17 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                cx="12"
                cy="12"
                r="9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      ) : (
        <div className="flex items-center w-full gap-2">
          <div className="flex justify-start flex-col">
            <h1 className="text-gray-100 dark:text-gray-500 text-md font-bold">
              {order?.booster?.champion_name?.length < 0
                ? "Aguarde até que um booster aceite seu serviço!"
                : profile.name}
            </h1>
            {profile.id.length === 0 ? null : (
              <div className="flex flex-row items-center gap-0.5">
                <div
                  className={`${
                    profile.online ? "bg-[#56BD79]" : "bg-gray-100"
                  } rounded-xl w-2.5 h-2 drop-shadow`}
                ></div>
                <div className="flex w-full justify-center items-center rounded-xl text-xs">
                  {profile.online
                    ? `Online`
                    : `Offline ${
                        profile.lastSeen
                          ? "- Visto pela última vez " + profile.lastSeen
                          : ""
                      }`}
                </div>
              </div>
            )}
          </div>
          {user.type_permission === "ADMIN" && (
            <button onClick={handleEditClick}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3786 8.44975L8.96376 17.8648C8.68455 18.144 8.32895 18.3343 7.94177 18.4117L5.00003 19.0001L5.58838 16.0583C5.66582 15.6711 5.85612 15.3155 6.13532 15.0363L15.5502 5.62132M18.3786 8.44975L19.7929 7.03553C20.1834 6.64501 20.1834 6.01184 19.7929 5.62132L18.3786 4.20711C17.9881 3.81658 17.355 3.81658 16.9644 4.20711L15.5502 5.62132M18.3786 8.44975L15.5502 5.62132"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileImage;
