import React, { useState } from "react";
import Navigation from "@components/Dashboard/Sidebar"
import Topbar from "@components/Dashboard/Topbar"
import Main from "@components/Dashboard/mainDashboard/Main"
import Content from "@components/Dashboard/mainDashboard/Content"
import {
  Outlet, BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import "@styles/dashboard.css"
import { useAuth } from "@context/AuthContext";
import api from "@services/client/api";
import Awaiting from "@pages/awaitingPayment";
import CarrinhoModal from "@components/(check)";
import { useCheckout } from "@context/CheckoutContextProvider";
import { useRouletteContext } from "@components/(Roulette)/roulette/Context/RouletteProvider";
import { ClaimModal } from "@components/(Roulette)/roulette/awards/claim-modal";

type Props = {
  userType: 'ADMIN' | 'CLIENT' | 'BOOSTER';
}

export default function Dashboard_Layout({ userType }: Props) {
  const { token, user } = useAuth()
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPremium, setIsPremium] = React.useState(false);
  const { openCheckout } = useCheckout()
  const { rouletteUserData, roulettePrizes, raffledItem, onSpinningEnd, onSpinningStart, claimModal } = useRouletteContext()

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.type_permission === 'CLIENT') {
          const response = await api.post(
            '/checkout/is-premium',
            {}, 
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          const data = response.data;
          setIsPremium(data.isPremium);
        }
      } catch (error) {
        console.log('Failed to get session data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, user]);

  
  if (!isLoading && user && user.type_permission === 'CLIENT' && !isPremium) {
    return <Awaiting />
  }

  const shouldShowClaimModal = claimModal && raffledItem?.item.showPopup;
  
  return (
    <>
      {openCheckout ? <CarrinhoModal/> : null}
      {shouldShowClaimModal ? <ClaimModal onClose={() => null} /> : null}
      <Navigation userType={userType} />
      <Main>
        <Topbar userType={userType} />
        <Content>
          <Outlet />
        </Content>
      </Main>
    </>
  )
}
