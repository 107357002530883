import React, { useEffect, useState, useTransition } from "react";
import Close from "@assets/Buy/Close.svg";
import Client from "@assets/Table/Client.svg";
import Check from "@assets/Info/IconCheck.svg";
import "@assets/Coockie.png";
import "@styles/popup.css";
import { ServiceRequests } from "@utils/types";
import api from "@services/client/api";
import { toast } from "react-toastify";
import Info from "@assets/Info/IconInfo.svg";
import WhiteSpin from "@assets/WhiteSpin.svg";
import { useTranslation } from "react-i18next";

type Props = {
  token: string;
  infoService: ServiceRequests | undefined;
};

const PopupPendingPayment = ({ token, infoService }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleApprovePayment = async (service_id: string) => {
    setLoading(true);

    try {
      await api.patch(
        `/requests/paid_by_admin/${service_id}`,
        { data: "any" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.info(`O pedido foi marcado como aprovado.`);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  if (!infoService) {
    toast.error(
      `O pedido selecionado não foi encontrado. Tente atualizar a página antes de tentar novamente.`
    );
    setShowPopup(false);
  }

  const handleRedirect = (id: string) => {
    window.location.href = `/dashboard/chat/${id}`;
  };

  return (
    <>
      {showPopup && infoService && (
        <div className="bg-overlay">
        <div
          className={`fixed z-10 inset-6 flex justify-center items-center`}
        >
          <div className="bg-blue-50 w-full sm:w-[980px] mx-auto border border-secondary-500 rounded-xl py-4 px-4">
              <div className="title w-full flex justify-between items-center">
                <div className="text-left mb-2 flex items-center gap-2">
                  <img src={Client} alt={Client} />
                  <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                    {t("table.service")}:
                  </h2>
                    <h2 className="mx-2 text-gray-500 text-2xl font-normal">
                      {" "}
                      #{infoService.ecommerce_id}
                    </h2>
                </div>
                <div className="flex items-center gap-2">
                {infoService.is_refund ? (
                    <button
                      className="bg-blue-20 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2"
                      onClick={() => handleRedirect(infoService.id)}
                    >
                      <img src={Info} alt="Info" />
                      {t("tools.more")}
                    </button>
                  ) : (
                    <button
                      className={`bg-blue-20 rounded-lg border border-gray-100/40 shadow-innertransition-all duration-300 hover:bg-blue-1001/20 px-4 py-3 flex items-center text-gray-500 text-sm font-semibold gap-2 ${
                        loading && "bg-[#56BD79]"
                      }`}
                      onClick={() => handleApprovePayment(infoService.id)}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <img
                            className="w-[24px] h-[24px] animate-spin"
                            src={WhiteSpin}
                            alt={"Spin"}
                          />
                          <p>Carregando...</p>
                        </>
                      ) : (
                        <>
                          <img src={Check} alt="Close" />
                          <p>Realizar Pagamento</p>
                        </>
                      )}
                    </button>
                  )}
                  <button className="p-2" onClick={handleClosePopup}>
                    <img src={Close} alt="Close" />
                  </button>
                </div>
              </div>
              <div className="bg-blue-50 p-4 rounded-2xl mt-4">
                <h2 className="mr-2 text-gray-100 text-base font-bold">
                  *{t("tools.more")}
                </h2>
                <div className="grid md:flex justiy-center items-start justify-between mt-4">
                  <div className="w-[48%]">
                    <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        Booster
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.booster.champion_name}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between py-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("table.commission")}
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.booster.gain_percentage}%
                      </h2>
                    </div>
                    <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.valueservice")}
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        R${" "}
                        {Number(infoService.price).toFixed(2).replace(".", ",")}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between py-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.valuebooster")}
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.reason_finalized_request ===
                        "servico finalizado" ? (
                          <span>
                            R${" "}
                            {(
                              (Number(infoService.price) *
                                infoService.booster.gain_percentage) /
                              100
                            )
                              .toFixed(2)
                              .replace(".", ",")}
                          </span>
                        ) : (
                          <span>
                            R${" "}
                            {Number(
                              infoService.amount_received_booster_others_reasons
                            )
                              .toFixed(2)
                              .replace(".", ",")}
                          </span>
                        )}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.date")}
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {new Date(infoService.created_at).toLocaleDateString(
                          "pt-BR"
                        )}
                      </h2>
                    </div>
                  </div>
                  {/* Informações Serviço */}
                  <div className="w-[48%]">
                    <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.valueelo")}
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.reason_finalized_request ===
                        "servico finalizado" ? (
                          <span>
                            R${" "}
                            {(
                              Number(infoService.price) -
                              (Number(infoService.price) *
                                infoService.booster.gain_percentage) /
                                100
                            )
                              .toFixed(2)
                              .replace(".", ",")}
                          </span>
                        ) : infoService.reason_finalized_request ===
                            "Abandono de serviço" ||
                          infoService.reason_finalized_request ===
                            "REEMBOLSO" ? (
                          <span>
                            R${" "}
                            {Number(
                              Number(infoService.new_value_service) -
                                Number(
                                  infoService.amount_received_booster_others_reasons
                                )
                            )
                              .toFixed(2)
                              .replace(".", ",")}
                          </span>
                        ) : (
                          <span>
                            R${" "}
                            {Number(
                              Number(infoService.price) -
                                Number(
                                  infoService.amount_received_booster_others_reasons
                                )
                            )
                              .toFixed(2)
                              .replace(".", ",")}
                          </span>
                        )}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between py-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        PIX:
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {/* R${infoService.price} */}
                        {infoService.booster.payment_key}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("tools.reason")}
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.reason_finalized_request ===
                        "servico finalizado"
                          ? "Serviço foi finalizado normalmente"
                          : infoService.reason_finalized_request}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between">
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {t("table.description")}
                      </h2>
                      <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.finalized_description
                          ? infoService.finalized_description
                          : "Sem descrição"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupPendingPayment;
