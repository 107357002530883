import React from 'react';
import Automatico from "@assets/Chat/Blitz.png";
import Ryze from "@assets/Chat/Ryze.png";

interface AutoMessageProps {
  name: string;
  message: string;
}

const AutoMessage: React.FC<AutoMessageProps> = ({ name, message }) => {
  return (
    <div className={`grid justify-start`}>
      <div className={`flex gap-2 mb-4 items-start`}>
        <img src={name === 'Blitz Bot' ? Automatico : Ryze} alt={`${name} Icon`} />
        <div className={`pt-2 p-4 bg-blue-20 border-2 border-primary-500 rounded-2xl rounded-tl-none`}>
          <div className="flex items-center gap-4">
            <p className="text-sm font-semibold">{name}</p>
            <div className="bg-gray-100 rounded-full px-4 py-2">
              <p className="text-xs font-light">Mensagem automática</p>
            </div>
          </div>
          <p className="text-sm font-normal mt-2">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default AutoMessage;