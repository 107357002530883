'use client'

import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { IResponseCheckout } from 'types/checkout/IResponseCheckout';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';
import { createCheckout, createCheckoutItem, deleteCheckoutItem, getCheckoutPrice, updateCoupon, updateCurrency, updateManiacoins } from '@services/server/checkout';
import { IResponseCheckoutItem } from 'types/checkout_item/IResponseItem';
import { CheckoutPriceResult } from 'types/checkout/ICheckoutPriceResult';
import { ICheckoutItem } from 'types/checkout_item/ICheckoutItem';
import { io, Socket } from 'socket.io-client';

interface CheckoutContextType {
    checkout: IResponseCheckout | null;
    enable: boolean;
    removeCheckoutItem: (data: IResponseCheckoutItem) => void;
    toggleManiacoins: () => void;
    setCoupon: (coupon: string, action: string) => void;
    checkoutPrice: CheckoutPriceResult | null | undefined;
    setOpenCheckout: Dispatch<SetStateAction<boolean>>;
    addCheckoutItem: (data: ICheckoutItem) => void;
    setCheckout: Dispatch<SetStateAction<IResponseCheckout | null>>;
    openCheckout: boolean;
    errorMsg: string;
    couponName: string;
}

interface CheckoutManager {
    couponName: string;
}

const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined);

interface CheckoutProviderProps {
    children: ReactNode;
}

export const CheckoutProvider: React.FC<CheckoutProviderProps> = ({ children }) => {
    const { user, token } = useAuth();

    const [checkoutPrice, setCheckoutPrice] = useState<CheckoutPriceResult | null>();
    const [openCheckout, setOpenCheckout] = useState<boolean>(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [enable, setEnableConnection] = useState<boolean>(false);
    const [checkout, setCheckout] = useState<IResponseCheckout | null>(null);
    const [checkoutManager, setCheckoutManager] = useState<CheckoutManager>({
        couponName: ""
    });

    const [errorMsg, setErrorMessage] = useState<string>('');

    const [isLoadingCheckout, setIsLoadingCheckout] = useState<boolean>(true);
    const [isLoadingItems, setIsLoadingItems] = useState<boolean>(true);

    useEffect(() => {
        if (!token) return;
        if (!checkout) return;
        const fetchData = async () => {
            try {
                try {
                    let response = await getCheckoutPrice(checkout.id, token);
                    if (response) {
                        setCheckoutPrice(response);
                    }
                } catch (error: any) {
                    console.log(error)
                    // toast.error(`${error}`);
                }

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [token, checkout, checkout?.checkoutItems]);

    useEffect(() => {
        if (!token || !user) {
            return;
        }
   
        const fetchCheckoutData = async () => {
            try {
                let response = await createCheckout({ client_id: user.id, currency: "BRL", channel: "dashboard" }, token); // Arrumar conversão aqui agr
                setCheckout(response);
                if (response.checkoutItems) {
                    for (const item of response.checkoutItems) {
                        if (item.coupon) {
                            setCheckoutManager(prevManager => ({
                                ...prevManager!,
                                couponName: item.coupon?.name || '',
                            }));
                            break;
                        }
                    }
                    setIsLoadingItems(false)
                }
            } catch (error: any) {
                console.log(error)
            }
            finally {
                setIsLoadingCheckout(false)
            }
        };
        fetchCheckoutData();
    }, [token]);

    const setCoupon = async (coupon: string, action: string) => {
        if (!token) return;

        if (!checkout) {
            return;
        }

        try {
            const res = await updateCoupon(checkout.id, coupon, action, token);
            if (action === 'add') {
                if (res && res.items && res.coupon) {
                    setCheckout(prevCheckout => ({
                        ...prevCheckout!,
                        checkoutItems: res.items
                    }));
                    setCheckoutManager(prevManager => ({
                        ...prevManager!,
                        couponName: res.coupon.name,
                    }));
                }
            }
            else {
                if (res && res.items) {
                    setCheckout(prevCheckout => ({
                        ...prevCheckout!,
                        checkoutItems: res.items
                    }));
                    setCheckoutManager(prevManager => ({
                        ...prevManager!,
                        couponName: "",
                    }));
                } 
            }
        } catch (error: any) {
            console.error("Erro ao atualizar o cupom:", error);
            toast.error(`${error}`);
        }
    };

    const toggleManiacoins = async () => {
        if (!token) return;

        if (!checkout) {
            return;
        }

        try {
            const response = await updateManiacoins(checkout.id, !checkout.maniaStatus, token);
        } catch (error: any) {
            console.log(error);
        } finally {
            setCheckout(prevCheckout => ({
                ...prevCheckout!,
                maniaStatus: !prevCheckout?.maniaStatus,
            }));
        }
    };

    const addCheckoutItem = async (data: ICheckoutItem) => {
        if (!token) return;

        if (!checkout || !data) {
            return;
        }

        try {
            let checkItem = await createCheckoutItem(data, token);
            if (checkItem) {
                setCheckout(prevCheckout => ({
                    ...prevCheckout!,
                    checkoutItems: [...prevCheckout!.checkoutItems, checkItem],
                }));
            }
        } catch (error: any) {
            setErrorMessage(error.message)
            setTimeout(() => {
                setErrorMessage('')
            }, 6000);
        }
    };

    const removeCheckoutItem = async (data: IResponseCheckoutItem) => {
        if (!token) return;

        if (!checkout || !data) {
            return;
        }
        try {
            let removedItem = await deleteCheckoutItem(data.id, token);
            setCheckout(prevCheckout => ({
                ...prevCheckout!,
                checkoutItems: prevCheckout!.checkoutItems.filter(item => item.id !== data.id),
            }));

            const remainingItems = checkout.checkoutItems.filter(item => item.id !== data.id);
            if (remainingItems.length === 0) {
                setCheckout(prevCheckout => ({
                    ...prevCheckout!,
                    maniaStatus: false,
                    discount_code: ''
                }));
                setCheckoutManager(prevManager => ({
                    ...prevManager!,
                    couponName: '',
                }));
            }
        } catch (error: any) {
            //  toast.error(`${error} `)
        }
    };


    return (
        <CheckoutContext.Provider value={{ ...checkoutManager, setOpenCheckout, errorMsg, setCheckout, addCheckoutItem, openCheckout, checkoutPrice, removeCheckoutItem, setCoupon, checkout, toggleManiacoins, enable }}>
            {children}
        </CheckoutContext.Provider>
    );
};

export const useCheckout = () => {
    const context = useContext(CheckoutContext);
    if (!context) {
        throw new Error('useCheckout must be used within a CheckoutProvider');
    }
    return context;
};
