import { useState } from 'react'
import Client from '@assets/Table/Client.svg'
import Close from '@assets/Buy/Close.svg'
import { ServiceRequests } from '@utils/types'
import api from '@services/client/api'
import { useAuth } from '@context/AuthContext'
import WhiteSpin from '@assets/WhiteSpin.svg'

type Props = {
  infoService: ServiceRequests
  showPopUp: boolean
  handleClosePopUp: () => void
}

const maskPhone = (value: string) => {
  value = value.replace(/\D/g, '')
  value = value.replace(/^(\d\d)(\d)/g, '($1) $2')
  value = value.replace(/(\d{5})(\d)/, '$1-$2')
  return value.substring(0, 15)
}

const PopUpApproveOrder = ({ infoService, showPopUp, handleClosePopUp }: Props) => {
  const { token } = useAuth()

  const [loading, setLoading] = useState(false);

  const [newValueBooster, setNewValueBooster] = useState('')
  const [backToAvailable, setBackToAvailable] = useState<string | null>('')

  async function approvedService() {
    setLoading(true);

    await api.patch(
      `/requests/finalized_admin/${infoService.id}`,
      {
        amount_received_booster:
          infoService.reason_finalized_request === 'servico finalizado'
            ? ''
            : newValueBooster,
        backToAvailable
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    setLoading(false);
    handleClosePopUp()
  }

  return (
    <>
      {showPopUp && (
        <div className="bg-overlay">
          <div
            className={`fixed z-10 inset-6 flex justify-center items-center`}
          >
            <div className="bg-blue-50 w-5/6 mx-auto border border-secondary-500 rounded-2xl py-4 px-4">
              <div className="bg-blue-20 w-full p-4 rounded-2xl mb-4">
                <div className="title w-full flex justify-between items-center">
                  <div className="text-left mb-2 flex items-center gap-2">
                    <img src={Client} alt={Client} />
                    <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                      Aprovar o Serviço:
                    </h2>
                    <div className="bg-blue-50 rounded-2xl p-2 text-center">
                      <h2 className="mr-2 text-gray-500 text-2xl font-bold">
                        {' '}
                        #{infoService.ecommerce_id}
                      </h2>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button className="p-2" onClick={handleClosePopUp}>
                      <img src={Close} alt="Close" />
                    </button>
                  </div>
                </div>
                <div className="bg-blue-50 p-4 rounded-2xl mt-4">
                  <h2 className="mr-2 text-gray-100 text-base font-bold">
                    *Confira todas as informações para aprovação do serviço.
                  </h2>
                  <div className="flex flex-row space-x-8">
                    <div className="w-[48%]">
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Invocador do Cliente:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.nick_game
                            ? infoService.nick_game
                            : '---'}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Nome do Cliente:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.client.name}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Telefone do Cliente:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                        {infoService.client?.telephone ? maskPhone(infoService.client.telephone) : 'Não informado'}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Discord do Cliente:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.client.discord}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Serviço:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.type_service} -{' '}
                          {infoService.game_service}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Valor do Serviço:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          R${' '}
                          {Number(infoService.price)
                            .toFixed(2)
                            .replace('.', ',')}
                        </h2>
                      </div>
                    </div>
                    <div className="w-[48%]">
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Descrição:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.type_service === 'COACH'
                            ? `${infoService.entry_level} - ${infoService.quantity_classes_or_wins} aula(s)`
                            : infoService.type_service === 'DUO BOOST' &&
                              infoService.desired_level === 'indefinido'
                            ? `${infoService.entry_level} - ${infoService.quantity_classes_or_wins} vitória(s)`
                            : infoService.type_service === 'VITÓRIAS AVULSAS'
                            ? `${infoService.entry_level} - ${infoService.quantity_classes_or_wins} vitória(s)`
                            : infoService.type_service === 'GARANTA SUA MD10'
                            ? `${infoService.entry_level} - ${infoService.quantity_classes_or_wins} partida(s)`
                            : `${infoService.entry_level} - ${infoService.desired_level}`}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Booster:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.booster.champion_name}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Comissão do Booster:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {`${infoService?.booster?.gain_percentage}%`}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Razão:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.reason_finalized_request ===
                          'servico finalizado'
                            ? 'Serviço foi finalizado normalmente'
                            : infoService.reason_finalized_request}
                        </h2>
                      </div>
                      <div className="flex items-center justify-between pb-4 border-b border-secondary-500">
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Justificativa:
                        </h2>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          {infoService.finalized_description
                            ? infoService.finalized_description
                            : 'Sem descrição'}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    {infoService.reason_finalized_request !==
                      'servico finalizado' &&
                    infoService.reason_finalized_request !==
                      'Abandono de serviço' ? (
                      <>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Valor a ser pago ao booster:
                        </h2>
                        <input
                          type="text"
                          placeholder="Valor Padrão R$0.00"
                          value={newValueBooster}
                          onChange={(e) => setNewValueBooster(e.target.value)}
                          className="bg-red-500 border border-secondary-500 rounded-xl px-4 py-2 w-full outline-none text-base text-gray-500"
                          required
                        />
                      </>
                    ) : infoService.reason_finalized_request ===
                      'Abandono de serviço' ? (
                      <>
                        <h2 className="mr-2 text-gray-500 text-base font-bold mt-4">
                          Valor do serviço até o momento (O restante será usado
                          ao voltar para disponíveis):
                        </h2>
                        <input
                          type="text"
                          placeholder="Valor Padrão R$0.00"
                          value={newValueBooster}
                          onChange={(e) => setNewValueBooster(e.target.value)}
                          className="bg-red-500 border border-secondary-500 rounded-xl px-4 py-2 w-full outline-none text-base text-gray-500"
                          required
                        />
                        <div className='flex flex-row'>
                          <input name='backToAvailable' type="checkbox" onChange={(e) => e.target.checked === true ? setBackToAvailable('Sim') : setBackToAvailable('Não')} required/>
                          <label htmlFor="backToAvailable">O serviço deve voltar a estar disponivel?</label>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <button
                    className={`bg-blue-20 rounded-full p-2 flex items-center text-gray-500 text-sm font-semibold gap-2 hover:bg-[#56BD79] active:bg-[#56BD79] ${
                      loading && 'bg-[#56BD79]'
                    }`}
                    onClick={() => approvedService()}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <img
                          className="w-[24px] h-[24px] animate-spin"
                          src={WhiteSpin}
                          alt={'Spin'}
                        />
                        <p>Carregando...</p>
                      </>
                    ) : (
                      <>
                        <p>Enviar</p>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PopUpApproveOrder
